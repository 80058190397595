import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `

    INITIALIZE_TOF_SERVICE_REDUCER

    SET_SELECTED_SERVICE_CONTRACT_PLANS
    REMOVE_SELECTED_SERVICE_CONTRACT_PLANS
    RESET_SELECTED_PLANS

    SET_TYPES_OF_SERVICES_MODAL_VIEW

    CREATE_SERVICE_CONTRACT_ORDER_INIT
    CREATE_SERVICE_CONTRACT_ORDER_SUCCESS
    CREATE_SERVICE_CONTRACT_ORDER_FAILURE

    FETCH_SERVICE_ORDER_INIT
    FETCH_SERVICE_ORDER_SUCCESS
    FETCH_SERVICE_ORDER_FAILURE

    FETCH_SERVICE_ORDER_ACTIVE_STEP_INIT
    FETCH_SERVICE_ORDER_ACTIVE_STEP_SUCCESS
    FETCH_SERVICE_ORDER_ACTIVE_STEP_FAILURE
    RESET_SERVICE_ORDER_ACTIVE_STEP

    FETCH_SERVICE_ORDER_LIST_INIT
    FETCH_SERVICE_ORDER_LIST_SUCCESS
    FETCH_SERVICE_ORDER_LIST_FAILURE

    FETCH_SERVICE_CONTRACT_LIST_INIT
    FETCH_SERVICE_CONTRACT_LIST_SUCCESS
    FETCH_SERVICE_CONTRACT_LIST_FAILURE

    FETCH_SERVICE_DESCRIPTION_INIT
    FETCH_SERVICE_DESCRIPTION_SUCCESS
    FETCH_SERVICE_DESCRIPTION_FAILURE

    FETCH_SERVICE_CONFIG_INIT
    FETCH_SERVICE_CONFIG_SUCCESS
    FETCH_SERVICE_CONFIG_FAILURE

    FETCH_COUPONS_LIST_INIT
    FETCH_COUPONS_LIST_SUCCESS
    FETCH_COUPONS_LIST_FAILURE

    APPLY_COUPON_INIT
    APPLY_COUPON_SUCCESS
    APPLY_COUPON_FAILURE

    REMOVE_COUPON_INIT
    REMOVE_COUPON_SUCCESS
    REMOVE_COUPON_FAILURE
`,
    {
        prefix: "tofService/"
    }
);

