import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_DETAILS
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE

    UPDATE_SSR_STATUS

    FETCH_SIMILAR_CARS
    FETCH_SIMILAR_CARS_SUCCESS
    FETCH_SIMILAR_CARS_FAILURE
    
    FETCH_PRICE_BENEFIT_INIT
    FETCH_PRICE_BENEFIT_SUCCESS
    FETCH_PRICE_BENEFIT_FAILURE

    FETCH_SIMILAR_CARS_FOR_MODAL_INIT
    FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS
    FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE

    FETCH_SIMILAR_CARS_COMPARE
    FETCH_SIMILAR_CARS_COMPARE_SUCCESS
    FETCH_SIMILAR_CARS_COMPARE_FAILURE

    SET_GS_GALLERY_DATA

    UPDATE_SPINCAR_MODAL_STATUS

    SEND_REPORT_NOTIFICATION_SUCCESS
    REFRESH_COUPON_LIST

    FETCH_HAND_PICKED_CARS_INIT
    FETCH_HAND_PICKED_CARS_SUCCESS
    FETCH_HAND_PICKED_CARS_FAILURE
    COUPON_INFO_FETCHED
    TOGGLE_STICKY_TABS
    TOGGLE_PRICE_BENIFITS
`,
    {
        prefix: "cardetails/"
    }
);
