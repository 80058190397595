
/* eslint-disable max-params */
import { SellerVariantService, C2BOmsService } from "../../../service";
import { generateSellOnlineAppointmentPayload } from "../../../utils/appointment-flow";
import Types from "./types";
import { reducerConstants } from "../../../constants/reducer-constant";
import { orderType } from "./constants";

const setLeadBrand = ({ data }) => ({
    type: Types.SET_EVALUATION_BRAND,
    data
});

const setLeadModel = ({ data }) => ({
    type: Types.SET_EVALUATION_MODEL,
    data
});

const setLeadYear = ({ data }) => ({
    type: Types.SET_EVALUATION_YEAR,
    year: data
});

const setLeadCustomerData = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_CUSTOMER,
    customer: data
});

const setLeadDataNode = ({ data }) => ({ // set data via key:value pair
    type: Types.SET_EVALUATION_DATA,
    ...data
});

const setLeadDataVariants = (data) => ({
    type: Types.SET_EVALUATION_DATA_BY_VARIANTS,
    data
});

const setLeadBookingDetails = (data) => ({
    type: Types.SET_BOOKING_DETAILS,
    data
});

const fetchLeadAppointmentInfoRequest = () => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_REQUEST
});

export const fetchLeadAppointmentInfoSuccess = (data) => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_SUCCESS,
    data
});

const fetchLeadAppointmentInfoFailure = (error) => ({
    type: Types.FETCH_LEAD_APPOINTMENT_INFO_FAILURE,
    error
});

const fetchLeadAppointmentInfo = (orderId, secureToken = "") => (dispatch, getState) => {
    dispatch(fetchLeadAppointmentInfoRequest());
    const { user: { secureToken: userToken } = {} } = getState();
    if (!secureToken) {
        secureToken = userToken;
    }
    return new Promise((resolve, reject) => {
        C2BOmsService.getBvcOrderInfo(orderType.C2B_BANK_VALUATION, orderId, secureToken)
            .then(response => {
                dispatch(fetchLeadAppointmentInfoSuccess({
                    ...response.data
                }));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchLeadAppointmentInfoFailure(error));
                reject(error);
            });
    });
};

const fetchImageUploadConfigData = (secureToken) => () => {
    return new Promise((resolve, reject) => {
        C2BOmsService.getBvcImageUploadConfig(orderType.C2B_BANK_VALUATION, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const uploadImageLink = (orderId, payload) => (_, getState) => {
    const { user: { secureToken } = {} } = getState();
    return new Promise((resolve, reject) => {
        C2BOmsService.bvcOrderImageUpload(orderType.C2B_BANK_VALUATION, orderId, payload, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateImageUploadStatus = (orderId, payload) => (_, getState) => {
    const { user: { secureToken } = {} } = getState();
    return new Promise((resolve, reject) => {
        C2BOmsService.bvcOrderImageUploadStatus(orderType.C2B_BANK_VALUATION, orderId, payload, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const createLeadAppointmentRequest = () => ({
    type: Types.CREATE_LEAD_APPOINTMENT_REQUEST
});

const createLeadAppointmentSuccess = (data) => ({
    type: Types.CREATE_LEAD_APPOINTMENT_SUCCESS,
    data
});

const resetLeadCreation = () => ({
    type: reducerConstants.BVC_CAR_EVALUATION
});

const createLeadAppointmentFailure = (error) => ({
    type: Types.CREATE_LEAD_APPOINTMENT_FAILURE,
    error
});

const createLeadAppointment = () => (dispatch, getState) => {
    dispatch(createLeadAppointmentRequest());
    const { user: { secureToken } = {} } = getState();
    const { bvcCarEvaluation, cities: { selectedCity }, user } = getState();
    const payload = generateSellOnlineAppointmentPayload(bvcCarEvaluation, selectedCity, user);
    return new Promise((resolve, reject) => {
        C2BOmsService.createBvcLeadAppointment(orderType.C2B_BANK_VALUATION, payload, secureToken)
            .then(response => {
                dispatch(createLeadAppointmentSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(createLeadAppointmentFailure(error));
                reject(error);
            });
    });
};

const initiateBvcOrderPayment = (orderId, payload) => (_, getState) => {
    const { user: { secureToken } = {} } = getState();
    return new Promise((resolve, reject) => {
        C2BOmsService.bvcOrderInitiatePayment(orderType.C2B_BANK_VALUATION, orderId, payload, secureToken)
            .then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const carSelfInspectionRequest = () => ({
    type: Types.CAR_SELF_INSPECTION_REQUEST
});

const carSelfInspectionSuccess = (data) => ({
    type: Types.CAR_SELF_INSPECTION_SUCCESS,
    data
});

const carSelfInspectionFailure = (error) => ({
    type: Types.CAR_SELF_INSPECTION_FAILURE,
    error
});

const fetchCarSelfInspection = (payload) => (dispatch) => {
    dispatch(carSelfInspectionRequest());
    return new Promise((resolve, reject) => {
        SellerVariantService.fetchCarSelfInspectionQuestions(payload)
            .then(response => {
                dispatch(carSelfInspectionSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(carSelfInspectionFailure(error));
                reject(error);
            });
    });
};

const setSellerRa = (payload) => (dispatch) => {
    dispatch({ type: Types.SET_SELLER_RA, data: payload });
};

const setCarConditionStepIndex = (index) => ({
    type: Types.SET_CAR_CONDITION_STEP_INDEX,
    index
});

const fetchBvcPaymentStatus = (orderId, ledgerId) => (_, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        C2BOmsService.getBvcOrderPaymentStatus(orderType.C2B_BANK_VALUATION, orderId, ledgerId, secureToken)
            .then(response => {
                resolve(response?.data);
            }).catch(error => {
                reject(error);
            });
    });
};

export {
    setLeadBrand,
    setLeadModel,
    setLeadYear,
    setLeadDataNode,
    setLeadBookingDetails,
    createLeadAppointment,
    initiateBvcOrderPayment,
    setLeadCustomerData,
    fetchLeadAppointmentInfo,
    resetLeadCreation,
    setLeadDataVariants,
    fetchCarSelfInspection,
    setSellerRa,
    setCarConditionStepIndex,
    fetchBvcPaymentStatus,
    fetchImageUploadConfigData,
    uploadImageLink,
    updateImageUploadStatus
};
