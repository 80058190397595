import { reducerConstants } from "../../../constants/reducer-constant";
import Types from "./types";

const updateLoanEligibility = (payload) => ({
    type: Types.UPDATE_LOAN_ELIGIBILITY,
    data: payload
});

// RESET Loan Eligibility
const resetLoanEligibility = () => ({
    type: reducerConstants.LOAN_ELIGIBILITY
});

export {
    updateLoanEligibility,
    resetLoanEligibility
};
