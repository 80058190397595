/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, Fragment } from "react";
import { format, isEqual, isToday, isTomorrow } from "date-fns";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), { ssr: false });
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import DateIcon from "./images/date-icon.svg";
import IconInfoOutline from "./images/info-outline-orange.svg";

import { SELECTED_SLOT_INITIAL_STATE } from "../td-schedule/reducers";

// Component
import ArrowIcon from "../../shared/arrow";
import TdTermsModal from "../td-terms-modal";
import Skeleton from "./skeleton";

// Constant
import { NUMBER } from "../../../constants/app-constants";
import HappyGiftBox from "./happy-giftbox.json";

// Tracking
import { trackDesktopCheckoutEvents } from "../checkout-revamp-v2/util";
import { BOOKING_TYPES } from "../../../constants/ae/checkout-constants";

const GiftBoxAnimationOption = {
    loop: true,
    autoplay: true,
    animationData: HappyGiftBox,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const TdScheduleSlot = ({
    slotAvailabilityDetails,
    isReturnSlotPage = false,
    isScheduleTestDriveShowMoreCTASelected = false,
    tdSlotFailureCallback = () => {},
    setSelectedSlotConnect,
    setScheduleTestDriveShowMoreCTAConnect,
    resetAddressConnect,
    bookingType,
    isComingSoon
}) => {

    const [showTC, setShowTC] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const { availableSlots = [], isFetched, selectedSlot } = slotAvailabilityDetails || {};
    const slotsList = availableSlots?.map((slot) => { return { ...slot, slotDate: new Date(slot.slotDate) }; });
    const isNoSlotAvailable = slotsList?.filter(({ slots }) => slots.length > NUMBER.ZERO)?.length === NUMBER.ZERO;

    const isVideoTd = bookingType === BOOKING_TYPES.VIRTUAL_TD;

    useEffect(() => {
        if (isFetched && isNoSlotAvailable) {
            tdSlotFailureCallback();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, isNoSlotAvailable]);

    const updateSelectedSlotInStore = (slot) => {
        setSelectedSlotConnect(slot || { ...SELECTED_SLOT_INITIAL_STATE, slotDate: format(selectedDate?.slotDate, "yyyy-MM-dd") });
    };

    const handleOnClickDate = (item) => {
        if (isEqual(selectedDate?.slotDate, item.slotDate)) {
            setSelectedDate(null);
            setSelectedSlotConnect(SELECTED_SLOT_INITIAL_STATE);
        } else {
            trackDesktopCheckoutEvents("checkoutSelectDate", bookingType);
            setSelectedDate(item);
            setSelectedSlotConnect({
                ...SELECTED_SLOT_INITIAL_STATE,
                slotDate: format(item.slotDate, "yyyy-MM-dd"),
                giftAvailable: item.giftAvailable
            });
        }
        resetAddressConnect();
        setSelectedTime(null);
    };

    const handleOnClickTimeSlot = (slot) => {
        if (selectedTime === slot.slotKey) {
            setSelectedTime(null);
        } else {
            setSelectedTime(slot.slotKey);
        }

        trackDesktopCheckoutEvents("checkoutSelectSlot", bookingType);
        updateSelectedSlotInStore(selectedTime !== slot.slotKey ? {
            ...slot,
            slotDate: format(new Date(selectedDate?.slotDate), "yyyy-MM-dd"),
            giftAvailable: selectedDate?.giftAvailable || false
        } : null);
    };

    const isShowMoreDatesButton = () => {
        return (!isScheduleTestDriveShowMoreCTASelected && slotsList.length > NUMBER.THREE);
    };

    const handleOnClickMoreDates = () => {
        setScheduleTestDriveShowMoreCTAConnect(true);
    };

    const getSlotContent = ({ giftAvailable, isSelected }) => {
        let slotTitle = <span />;
        let slotClasses = "styles.slotCard";

        if (giftAvailable) {
            slotTitle =  <span>Free Gift</span>;
            slotClasses += " styles.freeGift";
        }

        if (isSelected) {
            slotClasses += " styles.active styles.selected";
        }

        return { slotTitle, slotClasses };
    };

    useEffect(() => {
        if (slotsList.length > NUMBER.ZERO && (selectedDate === null || selectedDate === undefined)) {
            const defaultSlot = slotsList[NUMBER.ZERO];
            setSelectedDate(defaultSlot);
            setSelectedSlotConnect({ ...SELECTED_SLOT_INITIAL_STATE, slotDate: format(defaultSlot?.slotDate, "yyyy-MM-dd") });

            const { slots = [] } = defaultSlot;
            if (slots.length > NUMBER.ZERO) {
                setSelectedTime(slots[NUMBER.ZERO]?.slotKey);
                updateSelectedSlotInStore({
                    ...slots[NUMBER.ZERO],
                    slotDate: format(new Date(defaultSlot?.slotDate), "yyyy-MM-dd"),
                    giftAvailable: defaultSlot?.giftAvailable || false
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slotsList]);

    useEffect(() => {
        if (selectedSlot) {
            const { slotDate, slotKey } = selectedSlot || {};
            const selectedDateInStore = slotsList.find(({ slotDate: slotDateInList }) => isEqual(new Date(slotDate), new Date(slotDateInList)));
            setSelectedDate(selectedDateInStore);
            setSelectedTime(slotKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlot]);

    return (
        <Fragment>
            {!isFetched ? <Skeleton /> :
            <Fragment>
                <div styleName={"styles.dateTimeWrapper"}>
                    {isReturnSlotPage && <label styleName={"styles.flexBoxDateTime"}><img src={DateIcon} alt="Select Date and Time" /> When do you want us to pick your car?</label>}
                    <p styleName={"styles.titleText"}>Available dates</p>
                    <div styleName={"styles.availableDateWrap"}>
                        {slotsList?.slice(NUMBER.ZERO, isScheduleTestDriveShowMoreCTASelected ? slotsList.length : NUMBER.TWO).map((timeSlot, idx) => {
                            const { slotDate, giftAvailable } = timeSlot;
                            const isSelected = isEqual(selectedDate?.slotDate, slotDate);
                            const dateText = format(slotDate, "dd");
                            const dayText = format(slotDate, "EEE");
                            let slotTileHeader = isToday(slotDate) ? "TODAY" : "";
                            slotTileHeader = isTomorrow(slotDate) ? "TOMORROW" : slotTileHeader;
                            const { slotTitle, slotClasses } = getSlotContent({ giftAvailable, isSelected });
                            return (
                                <div
                                    key={`timeslot_${idx}`}
                                    styleName={slotClasses}
                                    onClick={() => handleOnClickDate(timeSlot)}
                                >
                                    <div styleName={"styles.cardBody"}>
                                        <div styleName={"styles.graySpace"}><p>{slotTileHeader}</p></div>
                                        {slotTitle}
                                        <p>{dayText}</p>
                                        <p>{dateText}</p>
                                    </div>
                                    <p>{`${timeSlot.slots.length} ${timeSlot.slots.length > 1 ? "Slots" : "Slot"}`}</p>
                                    <span styleName={"styles.caretDownIcon"} />
                                </div>
                            );
                        })}
                        {isShowMoreDatesButton() && (
                            <div styleName={"styles.slotCard styles.moreDates"} onClick={handleOnClickMoreDates}>
                                <div styleName={"styles.cardBody"}>
                                    <span><ArrowIcon /></span>
                                </div>
                                <p>More Dates</p>
                            </div>
                        )}
                    </div>
                    {
                        isVideoTd ?
                            <React.Fragment />
                            :
                            <div styleName={"styles.dateSlotHelpText"}>
                                {selectedDate?.giftAvailable ? (
                                    <Fragment>
                                        <span>
                                            <Lottie options={GiftBoxAnimationOption} height={28} width={28} />
                                        </span>
                                        <p>
                                            Complete a {!isComingSoon ? "Test Drive" : "Car Viewing"} on your chosen slot on this date to unlock gifts worth upto AED 250!
                                            <span styleName={"styles.tcText"} onClick={() => setShowTC(true)}>
                                                &nbsp;T&Cs apply
                                                <img
                                                    src={IconInfoOutline}
                                                    width={12}
                                                    height={12}
                                                    alt=""
                                                />
                                            </span>
                                        </p>
                                    </Fragment>
                                ) : (
                                    <p>Multiple customers are interested in this car! Book an earlier date to maximize your chances of test drive.</p>
                                )}
                            </div>
                    }
                    {selectedDate && (
                        <Fragment>
                            {/* <div styleName={"styles.emptyGraySpace"} /> */}
                            <div styleName={"styles.timeSlotWrapper"}>
                                <p styleName={"styles.titleText"}>Available time slots</p>
                                <ul styleName={"styles.timeSlots"}>
                                    {selectedDate.slots.map((slot, idx) => {
                                        const { from, to, slotKey } = slot;
                                        const startTime = format(new Date(from), "hh:mm aaa");
                                        const endTime = format(new Date(to), "hh:mm aaa");
                                        const isSelected = selectedTime === slotKey;
                                        return (
                                            <li key={`slot_${idx}`}
                                                onClick={() => handleOnClickTimeSlot(slot)}
                                                styleName={`${isSelected ? "styles.active" : ""}`}>
                                                {startTime} - {endTime}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Fragment>
                    )}
                </div>
                {showTC && (
                    <TdTermsModal onClose={() => setShowTC(false)} />
                )}
            </Fragment>
            }
        </Fragment>
    );
};

TdScheduleSlot.propTypes = {
    slotAvailabilityDetails: PropTypes.object,
    isReturnSlotPage: PropTypes.bool,
    isMRL: PropTypes.bool,
    setSelectedSlotConnect: PropTypes.func,
    setScheduleTestDriveShowMoreCTAConnect: PropTypes.func,
    isScheduleTestDriveShowMoreCTASelected: PropTypes.bool,
    tdSlotFailureCallback: PropTypes.func,
    resetAddressConnect: PropTypes.func,
    bookingType: PropTypes.string,
    isComingSoon: PropTypes.bool
};

export default TdScheduleSlot;
