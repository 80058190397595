import Types from "./types";
import { SecureListingService } from "../../../service";
import cleverTap from "../../../tracking/clevertap";
import { reducerConstants } from "../../../constants/reducer-constant";

const updateSelectedCar = (carId) => ({
    type: Types.UPDATE_SELECTED_CAR,
    carId
});

const showWishlistPopup = (showMyWishlist) => ({
    type: Types.SHOW_WISHLIST_POPUP,
    showMyWishlist
});

const fetchWishlistedCarsListInitiated = () => ({
    type: Types.FETCH_WISHLISTED_CARS_LIST_INIT
});

const fetchWishlistedCarsListSuccess = (data, params) => ({
    type: Types.FETCH_WISHLISTED_CARS_LIST_SUCCESS,
    data,
    params
});

const fetchWishlistedCarsListFailure = (error) => ({
    type: Types.FETCH_WISHLISTED_CARS_LIST_FAILURE,
    error
});

const fetchWishlistedCarsList = (token) => (dispatch) => {
    dispatch(fetchWishlistedCarsListInitiated());
    SecureListingService.fetchWishlistCarsList(token).then((response) => {
        dispatch(fetchWishlistedCarsListSuccess(response.data));
        cleverTap.updateWishlistedCarsBool(!!response?.data?.length);
    }).catch((error) => {
        dispatch(fetchWishlistedCarsListFailure(error));
    });
};

const fetchWishlistedCarsInitiated = () => ({
    type: Types.FETCH_WISHLISTED_CARS_INIT
});

const fetchWishlistedCarsSuccess = (data, params) => ({
    type: Types.FETCH_WISHLISTED_CARS_SUCCESS,
    data,
    params
});

const fetchWishlistedCarsFailure = (error) => ({
    type: Types.FETCH_WISHLISTED_CARS_FAILURE,
    error
});

const fetchWishlistedCars = (token, params = {}) => (dispatch) => {
    dispatch(fetchWishlistedCarsInitiated());
    SecureListingService.fetchWishlistCars(token, params).then((response) => {
        dispatch(fetchWishlistedCarsSuccess(response.data, params));
    }).catch((error) => {
        dispatch(fetchWishlistedCarsFailure(error));
    });
};

const addOrRemoveFromWishlistInitiated = () => ({
    type: Types.ADD_OR_REMOVE_FROM_WISHLIST_INIT
});

const addOrRemoveFromWishlistSuccess = (data) => ({
    type: Types.ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS,
    data
});

const addOrRemoveFromWishlistFailure = (error) => ({
    type: Types.ADD_OR_REMOVE_FROM_WISHLIST_FAILURE,
    error
});

const addOrRemoveFromWishlist = (token, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(addOrRemoveFromWishlistInitiated());
        SecureListingService.addOrRemoveFromWishlist(token, data).then((response) => {
            dispatch(addOrRemoveFromWishlistSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(addOrRemoveFromWishlistFailure(error));
            reject(error);
        });
    });
};

const resetWishlistedCars = () => ({
    type: reducerConstants.MY_WISHLIST
});

export {
    updateSelectedCar,
    showWishlistPopup,
    fetchWishlistedCars,
    fetchWishlistedCarsList,
    addOrRemoveFromWishlist,
    resetWishlistedCars
};
