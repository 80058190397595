/* eslint-disable complexity */
import { IDENTIFIERS } from "../../../constants/app-constants";
import { reducerConstants } from "../../../constants/reducer-constant";
import { B2CMasterData } from "../../../service";
import Types from "./types";

const fetchFaqInit = () => ({
    type: Types.FETCH_FAQ
});

// eslint-disable-next-line max-params
const fetchFaqSuccess = (content, isHomepage, isSeller, isBvc = false) => ({
    type: Types.FETCH_FAQ_SUCCESS,
    content,
    isHomepage,
    isSeller,
    isBvc
});

const resetSellerFaqs = () => ({
    type: reducerConstants.FAQS
});

const fetchFaqFailure = (error) => ({
    type: Types.FETCH_FAQ_FAILURE,
    error
});

const fetchFaq = (params) => dispatch => {
    const { isHomepage, type = IDENTIFIERS.BUY, city, isBvc = false } = params || {};
    const isSeller = type === IDENTIFIERS.SELL && !isBvc;
    dispatch(fetchFaqInit());
    return new Promise((resolve, reject) => {
        B2CMasterData.getFaq({ type, isHomepage, city })
            .then(response => {
                dispatch(fetchFaqSuccess(response.data, isHomepage, isSeller, isBvc));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchFaqFailure(error));
                reject(error);
            });
    });
};

export {
    fetchFaq,
    fetchFaqSuccess,
    resetSellerFaqs
};
