import { reducerConstants } from "../../../constants/reducer-constant";
import { CFService, userProfileService, b2cVasService} from "../../../service";
// import { getLatestServiceOrder } from "../../../utils/helpers/services-util";
// import { TOF_STEP_NAMES } from "./constants";
import Types from "./types";

// RESET TOF_SERVICE
const resetTofService = () => ({
    type: reducerConstants.TOF_SERVICE
});

// dynamic injection
const initializeTofServiceReducer = (source) => {
    return ({
    type: Types.INITIALIZE_TOF_SERVICE_REDUCER,
    source
});
};

// SERVICE PLANS
const setSelectedServiceContractPlans = (data) => (dispatch) => {
    dispatch({
        type: Types.SET_SELECTED_SERVICE_CONTRACT_PLANS,
        data
    });
};

const removeSelectedServiceContractPlans = (type) => (dispatch, getState) => {
    const { tofService: { selectedPlans } } = getState();
    const updateSelectedPlan = { ...selectedPlans };
    delete updateSelectedPlan[type];
    dispatch({
        type: Types.REMOVE_SELECTED_SERVICE_CONTRACT_PLANS,
        data: updateSelectedPlan
    });
};

const resetSelectedPlans = () => ({
    type: Types.RESET_SELECTED_PLANS
});

// SERVICE TYPE OF MODAL
const setTypesOfServicesModalView = (data) => ({
    type: Types.SET_TYPES_OF_SERVICES_MODAL_VIEW,
    data
});

// SERVICE SAVE
const createServiceContractOrderInit = () => ({
    type: Types.CREATE_SERVICE_CONTRACT_ORDER_INIT
});

const createServiceContractOrderSuccess = (data) => ({
    type: Types.CREATE_SERVICE_CONTRACT_ORDER_SUCCESS,
    data
});

const createServiceContractOrderFailure = () => ({
    type: Types.CREATE_SERVICE_CONTRACT_ORDER_FAILURE
});

const createServiceContractOrder = (params, tokenPassed) => (dispatch, getState) => {
    dispatch(createServiceContractOrderInit());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.createServiceContractOrder(params, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(createServiceContractOrderSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(createServiceContractOrderFailure());
                reject(err);
            });
    });
};

const initiateServiceContractOrder = (params) => (dispatch, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.initiateServiceContractOrder(params, secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateServiceContractOrder = (params) => (dispatch, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.updateServiceContractOrder(params, secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// SERVICE ORDER
const fetchServiceOrderInit = (withLoader) => ({
    type: Types.FETCH_SERVICE_ORDER_INIT,
    withLoader
});

const fetchServiceOrderSuccess = (data) => ({
    type: Types.FETCH_SERVICE_ORDER_SUCCESS,
    data
});

const fetchServiceOrderFailure = () => ({
    type: Types.FETCH_SERVICE_ORDER_FAILURE
});

const fetchServiceOrder = (orderId, withLoader = true) => (dispatch, getState) => {
    dispatch(fetchServiceOrderInit(withLoader));
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.getServiceContractOrder(orderId, secureToken)
            .then((response) => {
                const data = response?.data;
                dispatch(fetchServiceOrderSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceOrderFailure());
                reject(err);
            });
    });
};

// SERVICE ORDER - Active Step Data

const resetServiceOrderActiveStepData = () => ({
    type: Types.RESET_SERVICE_ORDER_ACTIVE_STEP
});

const fetchServiceOrderActiveStepDataInit = (data) => ({
    type: Types.FETCH_SERVICE_ORDER_ACTIVE_STEP_INIT,
    data
});

const fetchServiceOrderActiveStepDataSuccess = (data) => ({
    type: Types.FETCH_SERVICE_ORDER_ACTIVE_STEP_SUCCESS,
    data
});

const fetchServiceOrderActiveStepDataFailure = () => ({
    type: Types.FETCH_SERVICE_ORDER_ACTIVE_STEP_FAILURE
});

const fetchServiceOrderActiveStepData = ({orderId, serviceAppointmentId, token, stepName, withLoader = true}) => (dispatch, getState) => {
    const initData = { withLoader, stepName, serviceAppointmentId };
    dispatch(fetchServiceOrderActiveStepDataInit(initData));
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.getServiceOrderActiveStepData({ orderId, serviceAppointmentId, token: token || secureToken, stepName})
            .then((response) => {
                const data = response?.data;
                dispatch(fetchServiceOrderActiveStepDataSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceOrderActiveStepDataFailure());
                reject(err);
            });
    });
};

// INITIALIZE TOF SERVICE

// SERVICE ORDER LIST
const fetchServiceOrderListInit = () => ({
    type: Types.FETCH_SERVICE_ORDER_LIST_INIT
});

const fetchServiceOrderListSuccess = (data) => ({
    type: Types.FETCH_SERVICE_ORDER_LIST_SUCCESS,
    data
});

const fetchServiceOrderListFailure = () => ({
    type: Types.FETCH_SERVICE_ORDER_LIST_FAILURE
});

const fetchServiceOrderList = () => (dispatch, getState) => {
    dispatch(fetchServiceOrderListInit());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        b2cVasService.fetchServiceOrders(secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchServiceOrderListSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceOrderListFailure());
                reject(err);
            });
    });
};

// SERVICE CONTRACT LISTING
const fetchServiceContractListInit = () => ({
    type: Types.FETCH_SERVICE_CONTRACT_LIST_INIT
});

const fetchServiceContractListSuccess = (data) => ({
    type: Types.FETCH_SERVICE_CONTRACT_LIST_SUCCESS,
    data
});

const fetchServiceContractListFailure = () => ({
    type: Types.FETCH_SERVICE_CONTRACT_LIST_FAILURE
});

const fetchServiceContractList = (make = "", model = "") => (dispatch, getState) => {
    dispatch(fetchServiceContractListInit());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        CFService.getServiceContractList(make, model, secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchServiceContractListSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceContractListFailure());
                reject(err);
            });
    });
};

// SERVICE DESCRIPTION
const fetchServiceDescriptionInit = () => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_INIT
});

const fetchServiceDescriptionSuccess = (data) => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_SUCCESS,
    data
});

const fetchServiceDescriptionFailure = () => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_FAILURE
});

const fetchServiceDescription = () => (dispatch, getState) => {
    dispatch(fetchServiceDescriptionInit());
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        CFService.getServiceDescription(secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchServiceDescriptionSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceDescriptionFailure());
                reject(err);
            });
    });
};

// PAYMENT FLOW
const initiatePayment = (orderId, params) => (dispatch, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        userProfileService.initiatePayment(orderId, params, secureToken)
            .then((response) => {
                const data = response?.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getPaymentStatus = (orderId) => (_, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        userProfileService.getPaymentStatus(orderId, secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

// SERVICE CONFIG
const fetchServiceConfigInit = () => ({
    type: Types.FETCH_SERVICE_CONFIG_INIT
});

const fetchServiceConfigSuccess = (data) => ({
    type: Types.FETCH_SERVICE_CONFIG_SUCCESS,
    data
});

const fetchServiceConfigFailure = () => ({
    type: Types.FETCH_SERVICE_CONFIG_FAILURE
});

const fetchServiceConfig = () => (dispatch) => {
    dispatch(fetchServiceConfigInit());
    return new Promise((resolve, reject) => {
        b2cVasService.fetchServiceConfig()
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchServiceConfigSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceConfigFailure());
                reject(err);
            });
    });
};

// COUPON

const fetchCouponListForServiceOrderInit = () => ({
    type: Types.FETCH_COUPONS_LIST_INIT
});

const fetchCouponListForServiceOrderSuccess = (data) => ({
    type: Types.FETCH_COUPONS_LIST_SUCCESS,
    data
});

const fetchCouponListForServiceOrderFailure = () => ({
    type: Types.FETCH_COUPONS_LIST_FAILURE
});

const fetchCouponsForServiceOrder = (userIdPassed, tokenPassed) => (dispatch, getState) => {
    dispatch(fetchCouponListForServiceOrderInit());
    const { user: { secureToken, mobile }} = getState();

    return new Promise((resolve, reject) => {
        b2cVasService.getCouponsForServiceOrder(userIdPassed || mobile, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(fetchCouponListForServiceOrderSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchCouponListForServiceOrderFailure());
                reject(err);
            });
    });
};

const applyCouponOnServiceOrderInit = () => ({
    type: Types.APPLY_COUPON_INIT
});

const applyCouponOnServiceOrderSuccess = (data) => ({
    type: Types.APPLY_COUPON_SUCCESS,
    data
});

const applyCouponOnServiceOrderFailure = () => ({
    type: Types.APPLY_COUPON_FAILURE
});

const applyCouponOnServiceOrder = (payLoad, tokenPassed) => (dispatch, getState) => {
    dispatch(applyCouponOnServiceOrderInit());
    const { user: { secureToken }} = getState();

    return new Promise((resolve, reject) => {
        b2cVasService.applyCouponOnServiceOrder(payLoad, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(applyCouponOnServiceOrderSuccess(data));
                // dispatch(fetchServiceOrder(payLoad?.orderId, false));
                resolve(data);
            })
            .catch((err) => {
                dispatch(applyCouponOnServiceOrderFailure());
                reject(err);
            });
    });
};

const removeCouponOnServiceOrderInit = () => ({
    type: Types.REMOVE_COUPON_INIT
});

const removeCouponOnServiceOrderSuccess = (data) => ({
    type: Types.REMOVE_COUPON_SUCCESS,
    data
});

const removeCouponOnServiceOrderFailure = () => ({
    type: Types.REMOVE_COUPON_FAILURE
});

const removeCouponOnServiceOrder = (payLoad, tokenPassed) => (dispatch, getState) => {
    dispatch(removeCouponOnServiceOrderInit());
    const { user: { secureToken }} = getState();

    return new Promise((resolve, reject) => {
        b2cVasService.removeCouponOnServiceOrder(payLoad, tokenPassed || secureToken)
            .then((response) => {
                const data = response?.data || [ ];
                dispatch(removeCouponOnServiceOrderSuccess(data));
                // dispatch(fetchServiceOrder(payLoad?.orderId,  false));
                resolve(data);
            })
            .catch((err) => {
                dispatch(removeCouponOnServiceOrderFailure());
                reject(err);
            });
    });
};

export {
    resetTofService,
    initializeTofServiceReducer,
    setSelectedServiceContractPlans,
    removeSelectedServiceContractPlans,
    resetSelectedPlans,
    setTypesOfServicesModalView,
    fetchServiceOrder,
    resetServiceOrderActiveStepData,
    fetchServiceOrderActiveStepData,
    fetchServiceOrderList,
    fetchServiceContractList,
    fetchServiceDescription,
    fetchServiceDescriptionSuccess,
    initiatePayment,
    getPaymentStatus,
    initiateServiceContractOrder,
    updateServiceContractOrder,
    createServiceContractOrder,
    fetchServiceConfig,
    fetchCouponsForServiceOrder,
    applyCouponOnServiceOrder,
    removeCouponOnServiceOrder
};
