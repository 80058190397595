import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BuyCarInfo from "./component";
import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters, fetchListingCount, fetchRecentlyAddedCars, setIsReturningUser, updateSSRStatus } from "../filters/actions";
import { fetchFaq } from "../faq/actions";
import { updateLocationPopupShown } from "../../ae.configuration/actions";
import { initializeTestInjectionReducer } from "../../shared/testing-injection/actions";

const mapStateToProps = ({
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    filters: {
        listingCount: totalCars,
        isSSR,
        isListingCountLoading,
        recentlyAddedCars,
        isReturningUser,
        recommendedCars,
        showRecentlyUsedCars
    },
    config: {
        warrantyInfo: {
            warrantyMonths
        },
        activeSaleConfig = {}
    },
    faq: {
        homePageFaq
    }
}) => ({
    selectedCityCode,
    selectedCityName,
    isSSR,
    totalCars,
    isListingCountLoading,
    warrantyMonths,
    recentlyAddedCars,
    hasCitySkip,
    isReturningUser,
    homePageFaq,
    recommendedCars,
    showRecentlyUsedCars,
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    fetchListingCountConnect: fetchListingCount,
    updateSSRStatusConnect: updateSSRStatus,
    fetchRecentlyAddedCarsConnect: fetchRecentlyAddedCars,
    fetchFaqConnect: fetchFaq,
    setIsReturningUserConnect: setIsReturningUser,
    updateLocationPopupShownConnect: updateLocationPopupShown,
    initializeTestInjectionReducerConnect: initializeTestInjectionReducer
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyCarInfo);
