import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Images
import testDriveBanner from "./images/test-drive-car.png";
import tokenPaymentBanner from "./images/token-payment.jpg";
import scheduleTestDriveBanner from "./images/td-image.svg";

// Constants
import { TD_STEP_NAMES } from "../td-schedule/constants";

const getWhatNextData = (isComingSoon) => {
    return {
        [TD_STEP_NAMES.TD_SCHEDULE]: {
            title: "What happens next ?",
            subTitle: `${isComingSoon ? "Car Viewing" : "Test Drive"} your Car`,
            description: "Will help you with car queries, financing and recommendation",
            bannerImage: <img src={testDriveBanner} alt="Banner Image" width={60} height={60} />
        },
        [TD_STEP_NAMES.TD_COMPLETED]: {
            title: "What happens next ?",
            subTitle: "Token Payment",
            description: "Our agent will assist you with your car queries and financing needs",
            bannerImage: <img src={tokenPaymentBanner} alt="Banner Image" width={60} height={60} />
        },
        [TD_STEP_NAMES.TD_SELECTION]: {
            title: "What happens next ?",
            subTitle: `Schedule ${ isComingSoon ? "car viewing" : "test drive"}`,
            description: `Schedule ${ isComingSoon ? "car viewing" : "test drive"} after booking confirmation`,
            bannerImage: <img src={scheduleTestDriveBanner} alt="Banner Image" width={60} height={60} />
        }
    };
};

const PostBcTdWhatsNext = ({
    type = "",
    isComingSoon
}) => {
    const WHATS_NEXT_DATA = getWhatNextData(isComingSoon);
    return (
        <div styleName={"styles.whatHappenGrid"}>
            <h3>{WHATS_NEXT_DATA?.[type]?.title}</h3>
            <div styleName={"styles.flexRow"}>
                <div styleName={"styles.content"}>
                    <h4>{WHATS_NEXT_DATA?.[type]?.subTitle}</h4>
                    <p>{WHATS_NEXT_DATA?.[type]?.description}</p>
                </div>
                <figure>
                    {WHATS_NEXT_DATA?.[type]?.bannerImage}
                </figure>
            </div>
        </div>
    );
};

PostBcTdWhatsNext.propTypes = {
    type: PropTypes.string,
    isComingSoon: PropTypes.bool
};

export default PostBcTdWhatsNext;
