import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import PostBcTdCard from "./component";

const mapStateToProps = ({
    postBcDetails,
    config,
    carDetails
}) => ({
    postBcDetails,
    config,
    carDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBcTdCard);
