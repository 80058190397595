/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, Fragment } from "react";
import { differenceInSeconds } from "date-fns";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import IconInfo from "./images/info-outline.svg";
import TruckImage from "./images/truck.jpg";
import HubTd from "./images/hub-td.svg";
import IconAddCircle from "./images/add-circle.svg";
import PlusIconWhite from "./images/plus-icon-white.svg";
import ImageCarQueries from "./images/car-queries.png";
import ImageFinanceNeed from "./images/finance-needs.png";
import ImageCarRecommendation from "./images/car-recommendation.png";
import VideoCallIcon from "./images/video-call-img.svg";
import LinkIcon from "./images/link-icon.svg";

// Component
import { NoCarImageFoundPath } from "../no-car-image-found";
import Button from "../../shared/button";
import TdTermsModal from "../td-terms-modal";

// Utils
import { DATE_FORMAT_TYPE, formatDate } from "../../../utils/helpers/get-day-from-date";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { getCarName } from "../../../utils/helpers/car-properties.ae";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import HappyGiftBox from "./happy-giftbox.json";
import { BOOKING_TYPES } from "../../../constants/ae/checkout-constants";
import { trackDesktopCheckoutEvents } from "../checkout-revamp-v2/util";

const GiftBoxAnimationOption = {
    loop: true,
    autoplay: true,
    animationData: HappyGiftBox,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const PostBcTdCard = ({
    stepInfo = {},
    postBcDetails = {},
    currentUserTdBookings = [],
    config = {},
    totalEmptySlots = NUMBER.ZERO,
    handleCarCardSlot = () => {},
    handleEmptySlot = () => {},
    handleReSchedule = () => {},
    bookMoreTdHandler = () => {},
    carDetails = {}
}) => {

    const [isShowTcModal, setIsShowTcModal] = useState(false);
    const { isMRL = false, postBcConfig = {}, activeStepData = {}, bookingType } = postBcDetails || {};
    const { giftAvailable = false } = activeStepData || {};
    const isVideoTd = bookingType === BOOKING_TYPES.VIRTUAL_TD;
    const isMRLorVideoTd = isVideoTd || isMRL;
    const { address, slotEnd, slotStart } = stepInfo || {};
    const { shortAddress, mapLink, meetLink } = address || {};
    const { bookingAllowed = false } = config || {};
    const isShowDetailCard = !(isMRLorVideoTd) || totalEmptySlots === NUMBER.ZERO;
    const { tdConfig = {} } = postBcConfig || {};
    const { rescheduleBuffer = [] } = tdConfig || {};
    const { homeEarlyRescheduleHour, mrlEarlyRescheduleHour } = rescheduleBuffer[NUMBER.ZERO] || {};
    const tdRescheduleHour = isMRLorVideoTd ? mrlEarlyRescheduleHour : homeEarlyRescheduleHour;
    const { content: { isComingSoon }} = carDetails;

    const checkIfUnderRescheduleTime = () => {
        if (isMRLorVideoTd && tdRescheduleHour === NUMBER.ZERO) {
            return true;
        }
        return differenceInSeconds(new Date(slotStart), new Date()) > (tdRescheduleHour * NUMBER.SIXTY * NUMBER.SIXTY);
    };

    const isUnderRescheduleTime = checkIfUnderRescheduleTime();

    const onGetDirections = () => {
        window.open(mapLink, "_blank").focus();
    };

    const toggleTcModal = () => {
        setIsShowTcModal(!isShowTcModal);
    };

    const onMeetLinkClick = () => {
        trackDesktopCheckoutEvents("MeetlinkOpen", bookingType);
        window.open(meetLink, "_blank");
    };

    const getPageTextDetails = () => {
        if (isVideoTd) {
            return {
                addMoreToTdText: `You can book ${totalEmptySlots} more car in your viewing slot`
            };
        } else if (isMRL) {
            return {
                addMoreToTdText: `You can book ${totalEmptySlots} more car in your test drive slot`
            };
        } else {
            return {
                addMoreToTdText: `You can book ${totalEmptySlots} more car in your test drive slot`
            };
        }
    };

    const { addMoreToTdText } = getPageTextDetails() || {};

    return (
        <div styleName={"styles.cardBg"}>
            <div styleName={"styles.tdCardCtn"}>
                <div styleName={"styles.cardbody"}>
                    {
                        isVideoTd ?
                        <div styleName={"styles.dFlex"}>
                            <div styleName={"styles.content"}>
                                <h3>
                                    {formatDate(slotStart, DATE_FORMAT_TYPE.DAY_MONTH)}
                                    <span>{`${formatDate(slotStart, DATE_FORMAT_TYPE.TIME)} - ${formatDate(slotEnd, DATE_FORMAT_TYPE.TIME_AM_PM)}`}</span>
                                </h3>
                                <small>{formatDate(slotStart, DATE_FORMAT_TYPE.DAY_OF_WEEK)}</small>
                                <div styleName={"styles.meetLinkTextCtn"} onClick={onMeetLinkClick}>
                                    <img src={LinkIcon} />
                                    {meetLink}
                                </div>
                            </div>
                            <div styleName={"styles.videoCallIconCtn"}>
                                <div styleName={"styles.videoCallCtaWrap"} onClick={onMeetLinkClick}>
                                    <img src={VideoCallIcon} alt="video call icon"/>
                                    <p>Open Link</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div styleName={"styles.dFlex"}>
                            <div styleName={"styles.content"}>
                                <h3>
                                    {formatDate(slotStart, DATE_FORMAT_TYPE.DAY_MONTH)}
                                    <span>{`${formatDate(slotStart, DATE_FORMAT_TYPE.TIME)} - ${formatDate(slotEnd, DATE_FORMAT_TYPE.TIME_AM_PM)}`}</span>
                                </h3>
                                <small>{formatDate(slotStart, DATE_FORMAT_TYPE.DAY_OF_WEEK)}</small>
                                <p>{shortAddress}</p>
                            </div>
                            <div styleName={"styles.imageWrap"}>
                                {isMRL ?
                                    <img src={HubTd} onClick={onGetDirections} alt="Hub Icon" width="194" height="144" /> :
                                    <img src={TruckImage} alt="Direction Icon" width="254" height="144" />
                                }
                            </div>
                        </div>
                    }

                    <div styleName={"styles.dFlexRow"}>
                        {isUnderRescheduleTime && <span styleName={"styles.rescheduleCta"} onClick={handleReSchedule}>Reschedule</span>}
                        <p styleName={"styles.description"}>The {isComingSoon ? "Car Viewing" : "Test Drive"} will be conducted for 30 min.</p>
                    </div>

                    {isMRLorVideoTd ? (
                        <Fragment>
                            <div styleName={"styles.tdSlotGridWrap"}>
                                {(currentUserTdBookings || [])?.map((carCardsItem, cardCardsIndex) => {
                                    const { year = "", make = "", model = "", config: configTwo, mainImage = {}, variant = "", specs = "", optionsType = "" } = carCardsItem || {};
                                    const carFullName = getCarName({ year, make, model });
                                    const carImageUrl = (path) => fastlyUrl({ basePath: configTwo?.imageHost, path, type: imageTypes.aeCarDetailPreview });
                                    const hasImage = mainImage && mainImage.path;
                                    const mainImagePath = hasImage ? carImageUrl(mainImage.path) : NoCarImageFoundPath;
                                    const variantParsed = variant || "";
                                    const carVariantName = `${variantParsed || ""} ${specs ? `${variantParsed && " | "}${specs || ""} Specs` : ""} ${optionsType && `${(variantParsed || specs) && " | "}${optionsType} Option`}`;
                                    return (
                                        <div styleName={"styles.item"} key={`active_slot_${cardCardsIndex}}`} onClick={() => handleCarCardSlot(carCardsItem)}>
                                            {isShowDetailCard ?
                                                <div styleName={"styles.oneSlot"}  >
                                                    <div styleName={"styles.imgOverlay"}>
                                                        <img src={mainImagePath} alt="Car Image" width="64" height="64" />
                                                    </div>
                                                    <div styleName={"styles.imgCaption"}>
                                                        <p>{carFullName}</p>
                                                        <p styleName={"styles.variant"}>{carVariantName}</p>
                                                    </div>
                                                </div> :
                                                <div styleName={"styles.nonEmptySlot"}  >
                                                    <img src={mainImagePath} alt={carFullName} width="64" height="64" />
                                                    <div styleName={"styles.imgCaption"}>
                                                        <span>{carFullName}</span>
                                                        <span>{variant}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    );
                                })}

                                {Array(...new Array(totalEmptySlots))?.map((_, emptySlotIndex) => (
                                    <div styleName={"styles.item"} key={`empty_slot_${emptySlotIndex}}`} onClick={() => handleEmptySlot(emptySlotIndex)}>
                                        <div styleName={"styles.emptySlot"} >
                                            <img src={IconAddCircle} alt="Plus Icon" width="24" height="24" />
                                            <p>Empty Car Slot</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Fragment>
                                {(totalEmptySlots > NUMBER.ZERO) && (
                                    <p styleName={"styles.bookMore"}>
                                        {addMoreToTdText}
                                    </p>
                                )}
                                {bookingAllowed && (
                                    <div styleName={"styles.ctaWrap"}>
                                        <img src={PlusIconWhite} alt="Plus Icon" width="16" height="16" />
                                        <Button
                                            styleName={"styles.hubTdCtaDark"}
                                            text={"Add More Cars For Test Drive"}
                                            onClick={bookMoreTdHandler}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        </Fragment>
                    ) : (
                        <div styleName={"styles.carExpertGrid"}>
                            <h2>Car expert will come to your home</h2>
                            <div styleName={"styles.carExpertRow"}>
                                <div styleName={"styles.expertItem"}>
                                    <img src={ImageCarQueries} alt="Expert Icon" />
                                    <h3>Car queries</h3>
                                    <p>Will explain car features and purchase process</p>
                                </div>
                                <div styleName={"styles.expertItem"}>
                                    <img src={ImageFinanceNeed} alt="Finance needs" />
                                    <h3>Finance needs</h3>
                                    <p>Will explain the process of financing your car</p>
                                </div>
                                <div styleName={"styles.expertItem"}>
                                    <img src={ImageCarRecommendation} alt="Car recommendation" />
                                    <h3>Car recommendation</h3>
                                    <p>Will help in finding your dream  car as per your needs</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {giftAvailable && !isVideoTd && (
                <div styleName={"styles.giftWrap"}>
                    <span><Lottie options={GiftBoxAnimationOption} height={32} width={32} /></span>
                    <p>
                        After completing the {isComingSoon ? "car viewing" : "test drive"} collect your free gift from the {isComingSoon ? "car viewing" : "test drive"} Associate.
                        <span styleName={"styles.tncText"} onClick={toggleTcModal}> T&Cs apply <img src={IconInfo} alt="Info Icon" width="12" height="12" /></span>
                    </p>
                </div>
            )}

            {isShowTcModal && (
                <TdTermsModal
                    onClose={toggleTcModal}
                />
            )}
        </div>
    );
};

PostBcTdCard.propTypes = {
    stepInfo: PropTypes.object,
    postBcDetails: PropTypes.object,
    currentUserTdBookings: PropTypes.array,
    config: PropTypes.object,
    totalEmptySlots: PropTypes.number,
    handleCarCardSlot: PropTypes.func,
    handleEmptySlot: PropTypes.func,
    handleReSchedule: PropTypes.func,
    bookMoreTdHandler: PropTypes.func,
    carDetails: PropTypes.object
};

export default PostBcTdCard;
