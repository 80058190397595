import { C2BOmsService } from "../../../service";
import { orderType } from "../bvc-vehicle-info/constants";
import Types from "./types";
import { reducerConstants } from "../../../constants/reducer-constant";

const fetchLeadsSuccess = (data) => ({
    type: Types.FETCH_LEADS_SUCCESS,
    data
});

const fetchLeadsFailure = (error) => ({
    type: Types.FETCH_LEADS_FAILURE,
    error
});

export const resetBvcPendingLeads = () => ({
    type: reducerConstants.BVC_LEADS
});

export const fetchLeadsAgainstPhone = (phone) => (dispatch, getState) => {
    const payload = {
        phone
    };
    const { user: { secureToken } } = getState();

    return new Promise((resolve, reject) => {
        C2BOmsService.getBvcOrdersList(orderType.C2B_BANK_VALUATION, payload, secureToken)
            .then(response => {
                dispatch(fetchLeadsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchLeadsFailure(error));
                reject(error);
            });
    });
};
