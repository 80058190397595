/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

//Images
import ImageMrl from "./images/mrl-bg.png";
import IconPlay from "./images/play-circle-outline.svg";
import IconTick from "./images/tick.svg";
import DownloadAppBanner from "./images/download-app-banner.jpg";
import PlusIconWhite from "./images/plus-icon-white.svg";

// Components
import PostBcTdCard from "../post-bc-td-card";
import Skeleton from "./skeleton";
import VideoModal from "../video-modal";
import Button from "../../shared/button";
import CarDetailCard from "../../shared/car-detail-card";

// Utils
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import { getCarDetailCustomUrl } from "../../../utils/helpers/get-detail-page-url";

// Constants
import { NUMBER, TD_MRL_BANNER_VIDEO_URL } from "../../../constants/app-constants";
import { DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";
import { TD_STEP_NAMES } from "../td-schedule/constants";
import { BOOKING_TYPES } from "../../../constants/ae/checkout-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { getEventLabelForCheckoutFlow, trackDesktopCheckoutEvents } from "../checkout-revamp-v2/util";

const PostBcTdSummary = ({
    config = {},
    postBcDetails = {},
    carDetails = {},
    selectedCity = {},
    secureToken = "",
    isLoggedIn,
    mobile = "",
    fetchTdAllActiveBookingConnect,
    getNumberOfBookingsConnect
}) => {

    const history = useHistory();
    const [currentUserTdBookings, setCurrentUserTdBookings] = useState([]);
    const [isShowVideoModal, setIsShowVideoModal] = useState(false);

    const { isMRL = false, orderId = "", activeStepData = {}, tdAllActiveBookings = [], isTdAllActiveBookingsLoading = true, isFetched: postBcDetailsFetched, bookingType } = postBcDetails || {};
    const isVideoTd = bookingType === BOOKING_TYPES.VIRTUAL_TD;
    const { tdAddress = {}, tdSlotEnd, tdSlotStart, stepType, isLoading: isActiveStepLoading = false } = activeStepData || {};
    const postBcCardStepInfo = { address: tdAddress, slotEnd: tdSlotEnd, slotStart: tdSlotStart, stepType };
    const { maxUserBookingAllowed = NUMBER.ZERO, bookingAllowed = false } = config || {};
    const emptySlotsDiff = (maxUserBookingAllowed - tdAllActiveBookings?.length) || NUMBER.ZERO;
    const totalEmptySlots = emptySlotsDiff <= NUMBER.ZERO ? NUMBER.ZERO : emptySlotsDiff;

    useEffect(() => {
        if (config && !isTdAllActiveBookingsLoading) {
            trackDesktopCheckoutEvents("availableEmptySlot", bookingType, { eventLabel: `${totalEmptySlots} + ${getEventLabelForCheckoutFlow(bookingType)}`});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, isTdAllActiveBookingsLoading]);

    useEffect(() => {
        if (secureToken && isLoggedIn && mobile && postBcDetailsFetched && tdSlotStart && tdSlotEnd) {
            getNumberOfBookingsConnect(secureToken);
            fetchTdAllActiveBookingConnect(mobile, secureToken).then((response) => {
                const bookingList = (response || [])?.filter((item) => item?.tdSlotStart === tdSlotStart && item?.tdSlotEnd === tdSlotEnd && item?.bookingType === bookingType) || [];
                setCurrentUserTdBookings(bookingList);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken, isLoggedIn, mobile, tdSlotStart, tdSlotEnd, postBcDetailsFetched, isMRL, orderId]);

    const bookMoreTdHandler = () => {
        history.push(`/buy-used-cars-${dashedLowercase(selectedCity?.name || DEFAULT_AE_CITY_NAME)}?sf=cars24Hub:${currentUserTdBookings[0]?.parentHubLocationName}`);
        history.go(0);
    };

    const handleHomeAddMoreCta = () => {
        trackDesktopCustomEventsAE("checkoutHomeEmptySlot", { eventLabel: "addmorecar" });
        bookMoreTdHandler();
    };

    const handleMrlAddMoreCta = () => {
        if (isMRL) {
            trackDesktopCustomEventsAE("checkoutHubEmptySlot", { eventLabel: "addmorecar" });
        } else {
            trackDesktopCheckoutEvents("addMoreCarsPlusbutton", bookingType);
        }
        bookMoreTdHandler();
    };

    const handleEmptySlot = (emptySlotIndex = NUMBER.ZERO) => {
        trackDesktopCustomEventsAE("checkoutHubEmptySlot", { eventLabel: `${emptySlotIndex + NUMBER.ONE}add` });
        bookMoreTdHandler();
    };

    const handleCarCardSlot = (carData = {}) => {
        const { relativeURL } = getCarDetailCustomUrl({ content: carData });
        history.push(relativeURL);
    };

    const handleReScheduleTestDrive = () => {
        trackDesktopCustomEventsAE("checkoutTdReschedule", { eventLabel: isMRL ? "Hub" : "Home" });
        history.push(`${history.location.pathname}?type=${TD_STEP_NAMES.TD_SCHEDULE}&isReschedule=true`);
    };

    const handleVideoModal = () => {
        setIsShowVideoModal(!isShowVideoModal);
    };

    const getPageTextDetails = () => {
        if (carDetails?.content?.isComingSoon) {
            return {
                title: "Hub Car Viewing",
                subTitle: "on scheduling your car viewing",
                addMoreToTdText: `You can book ${totalEmptySlots} more car in your viewing slot`
            };
        } else if (isVideoTd) {
            return {
                title: "Video Car Viewing",
                subTitle: "on scheduling your video car viewing",
                addMoreToTdText: `You can book ${totalEmptySlots} more car in your viewing slot`
            };
        } else if (isMRL) {
            return {
                title: "Hub Test Drive",
                subTitle: "on scheduling your test Drive",
                addMoreToTdText: `You can book ${totalEmptySlots} more car for your test drive`
            };
        } else {
            return {
                title: "Home Test Drive",
                subTitle: "on scheduling your test Drive",
                addMoreToTdText: `You can book ${totalEmptySlots} more car for your test drive`
            };
        }
    };

    const { title, subTitle, addMoreToTdText } = getPageTextDetails() || {};

    return (
        <React.Fragment>
            <div styleName={"styles.leftSection"}>
                <h2 styleName={"styles.heading"}>
                    {title}
                </h2>
                <h3 styleName="styles.subHeading">
                    <strong>Congratulations!</strong> {subTitle}
                </h3>
                {(isTdAllActiveBookingsLoading || isActiveStepLoading) ?
                    <Skeleton /> :
                    <PostBcTdCard
                        stepInfo={postBcCardStepInfo}
                        currentUserTdBookings={currentUserTdBookings}
                        totalEmptySlots={totalEmptySlots}
                        handleReSchedule={handleReScheduleTestDrive}
                        handleEmptySlot={handleEmptySlot}
                        handleCarCardSlot={handleCarCardSlot}
                        bookMoreTdHandler={handleMrlAddMoreCta}
                    />
                }
            </div>
            <div styleName={"styles.rightSection"}>
                <div styleName={"styles.bookingId"}>
                    Booking ID : #{orderId}
                </div>
                {!isVideoTd && <React.Fragment>
                    {isMRL ?
                        <div styleName={"styles.mrlSection"}>
                            <figure styleName={"styles.videoWrap"} onClick={handleVideoModal}>
                                <img src={ImageMrl} alt="MRL Image" />
                                <figcaption styleName={"styles.playButton"}>
                                    <img src={IconPlay} alt="Play Icon" />
                                    Watch Video Now
                                </figcaption>
                            </figure>
                            <div styleName={"styles.sectionContent"}>
                                <h3>Explore Our MRL</h3>
                                <p>Test Drive and choose your best car</p>
                                <ul>
                                    <li><img src={IconTick} alt="Tick Icon" />Walk In to UAE’s Largest Car Hub</li>
                                    <li><img src={IconTick} alt="Tick Icon" />Our Hub consists of 1200+ Certified Cars</li>
                                </ul>
                            </div>
                        </div> :
                        <div styleName={`styles.homeSection ${totalEmptySlots === NUMBER.ZERO ? "styles.bookingAllowed" : ""}`}>
                            <CarDetailCard vehiclePrice={NUMBER.ZERO} carDetails={carDetails} />
                            {(totalEmptySlots > NUMBER.ZERO) && (
                                <p styleName={"styles.totalEmpty"}>{addMoreToTdText}</p>
                            )}
                            {bookingAllowed && (
                                <div styleName={"styles.buttonWrap"}>
                                    <img styleName={"styles.addIcon"} src={PlusIconWhite} alt="Plus Icon" width="16" height="16" />
                                    <Button
                                        text={"Add More Cars For Test Drive"}
                                        onClick={handleHomeAddMoreCta}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </React.Fragment>}
                <div styleName={"styles.downloadExp"}>
                    <img width={294} src={DownloadAppBanner} alt="Download Section" />
                </div>
            </div>

            {isShowVideoModal && (
                <div styleName={"styles.videoModalWrap"}>
                    <VideoModal
                        wrapperClass="videoWrapper"
                        onClose={handleVideoModal}
                        url={TD_MRL_BANNER_VIDEO_URL}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

PostBcTdSummary.propTypes = {
    selectedCity: PropTypes.object,
    config: PropTypes.object,
    postBcDetails: PropTypes.object,
    carDetails: PropTypes.object,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    mobile: PropTypes.string,
    getNumberOfBookingsConnect: PropTypes.func,
    fetchTdAllActiveBookingConnect: PropTypes.func
};

export default PostBcTdSummary;
