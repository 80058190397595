/* eslint-disable complexity */
/* eslint-disable max-statements */
import qs from "querystring";

import Init from "./init";

// Constant
import { FILTER_ENTITIES } from "./constants";
import { appUrl } from "../../constants/url-constants";
import { DEFAULT_CITY, DEFAULT_AE_CITY_NAME } from "../../constants/app-constants";

// Utilities
import parseLocationSearchParams from "../helpers/parse-location-search-params";
import getAppliedFilter from "./get-applied-filter";
import dashedLowercase from "../helpers/dashed-lowercase";
import gaId from "../../utils/helpers/get-ga-from-cookie";

export const PATH_TYPE = {
    CUSTOM: "custom",
    SEO: "seo"
};

class Filters extends Init {
    constructor({ cityCode, cityName }) {
        super();
        this._cityCode = cityCode || DEFAULT_CITY.AE.code;
        this._cityName = cityName || dashedLowercase(DEFAULT_AE_CITY_NAME);
        this._ignoredUrlOptions = [
            "serveWarrantyCount",
            "gaId",
            "mobile",
            "listingCohortABTest"
        ];
    }
    normalizeCity() {
        try {
            return this._cityName.toLowerCase().split(" ").join("-");
        } catch (e) {
            return this._defaultCity;
        }
    }

    getRedirectURL(entities, data) {
        const url = ["buy", "used"];
        if (Array.isArray(entities)) {
            this.getPriorityOrder().forEach((item) => {
                if (entities.includes(item)) {
                    url.push(...(this.getEntityModifier(item))(data));
                }
            });
        } else {
            url.push("cars");
        }
        url.push(this.normalizeCity());
        return url.join("-");
    }

    getRelativeURL() {
        return `/${this.getRedirectURL(...arguments)}/`;
    }

    getAbsoluteURL() {
        return `${appUrl()}/${this.getRedirectURL(...arguments)}/`;
    }

    /*
    single filter, single option,
    single filter, multiple option,
    single filter, single option, single sub option,
    single filter, single option, multiple sub option,
    single filter, multiple option, multiple sub option

    &sf=make:Honda-sub-model:Honda Activa-or-make:Bajaj-sub-model:Bajaj Pulsar 150-or-make:Hero
    &rf=year:2010;2015

    {
        sf: [

        ]
    }
    */
    getGfTypeOptionsArray(gfFilter, currentFilter, appliedFiltersList) {
        const gfOptionsArray = [];
        ((currentFilter && currentFilter.options) || []).forEach((filterOption) => {
            let appliedOptionString = "";
            const selectedAppliedFilter = getAppliedFilter({ filterKey: gfFilter.key, optionKey: currentFilter.key, subOptionKey: filterOption.key }, appliedFiltersList);
            if (selectedAppliedFilter) {
                appliedOptionString = `${currentFilter.key}:${filterOption.key}`;
            }
            if (appliedOptionString) {
                gfOptionsArray.push(appliedOptionString);
            }
        });
        return gfOptionsArray;
    }

    getOptionArray(currentFilter, appliedFiltersList) {
        let optionsArray = [];
        ((currentFilter && currentFilter.options) || []).forEach((filterOption) => {
            let appliedOptionString = "";
            const selectedAppliedFilter = getAppliedFilter({ filterKey: currentFilter.key, optionKey: filterOption.key }, appliedFiltersList);
            if (selectedAppliedFilter) {
                appliedOptionString = `${currentFilter.key}:${filterOption.key}`;
            }
            if (filterOption.subFilter) {
                const subFilterOptionList = [];
                filterOption.subFilter.options.forEach((subFilterOption) => {
                    if (getAppliedFilter({ filterKey: currentFilter.key, optionKey: filterOption.key, subOptionKey: subFilterOption.key }, appliedFiltersList)) {
                        subFilterOptionList.push(subFilterOption.key);
                    }
                });
                if (subFilterOptionList.length) {
                    appliedOptionString = `${currentFilter.key}:${filterOption.key}-sub-${filterOption.subFilter.key}:${subFilterOptionList.join(";")}`;
                }
            }
            if (appliedOptionString) {
                optionsArray.push(appliedOptionString);
            }
        });
        if (currentFilter && currentFilter.filterType === "gf") {
            (currentFilter && Object.values(currentFilter.groupFacet)).forEach((filterOption) => {
                let appliedOptionString = "";
                const selectedAppliedFilter = getAppliedFilter({ filterKey: currentFilter.key, optionKey: filterOption.key }, appliedFiltersList);
                if (selectedAppliedFilter && selectedAppliedFilter.isRangeFilter) {
                    appliedOptionString = `${filterOption.filterType}=${selectedAppliedFilter.optionKey}:${selectedAppliedFilter.min};${selectedAppliedFilter.max}`;
                }
                if (selectedAppliedFilter && filterOption.options) {
                    const gfOptions = this.getGfTypeOptionsArray(currentFilter, filterOption, appliedFiltersList);
                    appliedOptionString = `${filterOption.filterType}=${gfOptions.join("-or-")}`;
                }
                if (appliedOptionString) {
                    optionsArray.push(appliedOptionString);
                }
            });
        }
        if (currentFilter.key === FILTER_ENTITIES.DISCOUNT  || currentFilter.key === FILTER_ENTITIES.LOW_IMPERFECTION) {
            const discountOptions = appliedFiltersList.filter((item) => item.filterKey === currentFilter.key).map((filter) => `${filter.optionKey}:true`);
            optionsArray = [...discountOptions];
        }
        //For Exclude Filters
        if (currentFilter.key === FILTER_ENTITIES.COMING_SOON) {
            const discountOptions = appliedFiltersList.filter((item) => item.filterKey === currentFilter.key).map((filter) => `${filter.optionKey}:false`);
            optionsArray = [...discountOptions];
        }
        return optionsArray;
    }

    getFilterString(appliedFiltersList, appliedSuggestionsList, filters) {
        let filterString = "";
        const appliedFilters = [];
        Object.keys(filters).map((filterKey) => {
            let filterOptionArray = [];
            const currentFilter = filters[filterKey];
            if (currentFilter.filterType === "rf") {
                const selectedAppliedFilter = getAppliedFilter({ filterKey }, appliedFiltersList);
                if (selectedAppliedFilter) {
                    filterOptionArray.push(`${filterKey}:${selectedAppliedFilter.min};${selectedAppliedFilter.max}`);
                }
                const selectedAppliedSuggestion = getAppliedFilter({ filterKey }, appliedSuggestionsList);
                if (selectedAppliedSuggestion) {
                    filterOptionArray.push(`${filterKey}:${selectedAppliedSuggestion.min};${selectedAppliedSuggestion.max}`);
                }
            } else if (currentFilter.filterType === "gf") {
                const selectedAppliedFilter = getAppliedFilter({ filterKey }, appliedFiltersList);
                if (selectedAppliedFilter) {
                    filterOptionArray = [
                        ...filterOptionArray,
                        ...this.getOptionArray(currentFilter, appliedFiltersList)
                    ];
                }
            } else if (currentFilter.options || currentFilter.suggestions) {
                if (currentFilter.options) {
                    filterOptionArray = [
                        ...filterOptionArray,
                        ...this.getOptionArray(currentFilter, appliedFiltersList)
                    ];
                }
                if (currentFilter.suggestions) {
                    filterOptionArray = [
                        ...filterOptionArray,
                        ...this.getOptionArray(currentFilter, appliedSuggestionsList)
                    ];
                }
            }

            if (filterOptionArray.length) {
                if (currentFilter.filterType === "gf") {
                    appliedFilters.push(`${currentFilter.filterType}=${currentFilter.key}:(${filterOptionArray.join("-and-")})`);
                } else {
                    appliedFilters.push(`${currentFilter.filterType}=${filterOptionArray.join("-or-")}`);
                }
            }
        });
        filterString = appliedFilters.join("&");
        return filterString;
    }

    getListingPayload({ selectedFilters = [], selectedSuggestions = [], filters = {}, options = [], pathType = null, isGlobalUrl = false }) {
        const apiPayload = [this.getFilterString(selectedFilters, selectedSuggestions, filters)];
        const urlPayload = [...apiPayload];
        if (options) {
            const filteredOptions = { ...options };
            this._ignoredUrlOptions.forEach(i => delete filteredOptions[i]);
            urlPayload.push(qs.stringify(filteredOptions));
            apiPayload.push(qs.stringify(options));
        }
        const gaIdPayload = `sf=gaId:${gaId}`;
        const cityPayload = `sf=city:${this._cityCode}`;
        urlPayload.push(cityPayload);
        urlPayload.push(gaIdPayload);
        const urlString = urlPayload.filter(payload => !!payload).join("&");
        apiPayload.push(cityPayload);
        apiPayload.push(gaIdPayload);
        const payloadString = apiPayload.filter(payload => !!payload).join("&");

        let path = `buy-used-cars${this._cityName && !isGlobalUrl ? `-${dashedLowercase(this._cityName, "-")}` : "-uae/"}?${urlString}`;

        if (Array.isArray(selectedFilters) && selectedFilters.length === 1 && pathType === PATH_TYPE.SEO) {
            path = `buy-used-${selectedFilters[0].optionKey.toLowerCase() || ""}-cars${this._cityName ? `-${dashedLowercase(this._cityName, "-")}` : ""}?${urlString}`;
        }

        return {
            relativeURL: `/${path}`,
            payload: payloadString,
            absoluteURL: `${appUrl()}/${path}`
        };
    }

    getPaginationUrl({ history, page }) {
        const queryParams = parseLocationSearchParams(history.location.search);
        if (page <= 1) {
            delete queryParams.page;
        } else {
            queryParams.page = page;
        }

        const relativeURL = [
            history.location.pathname,
            qs.stringify(queryParams)
        ].filter(i => !!i).join("?");
        const absoluteURL = `${appUrl()}${relativeURL}`;

        return { absoluteURL, relativeURL, payload: relativeURL };
    }

}

export default Filters;
