export const TD_STEP_NAMES = {
    TD_SELECTION: "tdSelection",
    TD_SCHEDULE: "tdSchedule",
    TD_SUMMARY: "tdSummary",
    TD_COMPLETED: "tdCompleted",
    TD_SELECTION_COMING_SOON: "tdSelectionComingSoon",
    TD_SCHEDULE_COMING_SOON: "tdScheduleComingSoon"
};

export const TD_SCHEDULE_STEPS = {
    LOCATION_SELECTION: "locationSelection",
    SLOT_SELECTION: "slotSelection",
    ADDRESS_SELECTION: "addressSelection"
};

export const STEP_ICONS_STATUS = {
    PENDING: "pending",
    COMPLETED: "completed",
    BLOCK: "block"
};

