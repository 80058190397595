
export const reducerConstants = {
    USER: "user",
    CITIES: "cities",
    CONFIG: "config",
    CAR_DETAILS: "carDetails",
    CAR_LISTING: "carListing",
    FILTERS: "filters",
    LOCATION_PICKER: "locationPicker",
    CHECKOUT: "checkout",
    EMI_DETAILS: "emiDetails",
    EXPERIMENTS: "experiments",
    MY_BOOKINGS: "myBookings",
    MY_WISHLIST: "myWishlist",
    TOF_SERVICE: "tofService",
    TOF_SERVICE_SCHEDULE: "tofServiceSchedule",
    CAR_LOAN: "carLoan",
    SEO_CAR_DETAILS: "seoCarDetails",
    TOAST: "toast",
    BACK_TO_TOP: "backToTop",
    RECENTLY_VIEWED: "recentlyViewed",
    FAQ: "faq",
    USER_CF_DETAILS: "userCfDetails",
    CAR_CF_DETAILS: "carCfDetails",
    CHAT_ICON: "chatIcon",
    SALESFORCE: "salesforce",
    COMPARE_CARS: "compareCars",
    PAYMENT_STATUS: "paymentStatus",
    POST_BC_DETAILS: "postBcDetails",
    TD_SCHEDULE: "tdSchedule",
    C2C_TD_SCHEDULE: "c2cTdSchedule",
    DELIVERY_SCHEDULE: "deliverySchedule",
    FEEDBACK: "feedback",
    RETURN_AVAILABLE_SLOT: "returnAvailableSlot",
    POST_BC_VAS: "postBcVas",
    LOAN_PRE_APPROVAL: "loanPreApproval",
    POST_BOOKING_DELIVERY: "postBookingDelivery",
    AI_RECOMMENDED_CARS: "aiRecommendedCars",
    CAR_EVALUATION: "carEvaluation",
    BRANDS: "brands",
    MODELS: "models",
    YEARS: "years",
    SELLER_REVALUATION: "sellerRevaluation",
    CAR_STORES: "carStores",
    TESTIMONIALS: "testimonials",
    POST_PADETAILS: "postPADetails",
    SELLER_LEADS: "sellerLeads",
    SELLER_NPS: "sellerNps",
    LOAN_ELIGIBILITY: "loanEligibility",
    LOCATION: "location",
    FOOTER: "footer",
    MARKET_PLACE: "marketPlace",
    SELLER_CENTER: "sellerCenter",
    TESTING_INJECTION: "testingInjection",
    BVC_CAR_EVALUATION: "bvcCarEvaluation",
    BVC_LEADS: "bvcLeads"
};
