import { reducerConstants } from "../../../constants/reducer-constant";
import { CFService } from "../../../service";
import Types from "./types";

const setCarCfFlow = (status) => ({
    type: Types.SET_CAR_CF_FLOW,
    status
});

const getUserCFDetailsRequest = (data) => ({
    type: Types.GET_USER_CF_DETAILS_REQUEST,
    data
});

const getUserCFDetailsSuccess = (data) => ({
    type: Types.GET_USER_CF_DETAILS_SUCCESS,
    data
});

const getUserCFDetailsFailure = (error) => ({
    type: Types.GET_USER_CF_DETAILS_FAILURE,
    error
});

const getUserCFDetails = () => (dispatch, getState) => {
    dispatch(getUserCFDetailsRequest());
    const {user: {secureToken}} = getState();
    return new Promise((resolve, reject) => {
        CFService.getUserCFDetails(secureToken)
            .then(response => {
                dispatch(getUserCFDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getUserCFDetailsFailure(error));
                reject(error);
            });
    });
};

const createUserCFDetailsRequest = (data) => ({
    type: Types.CREATE_USER_CF_DETAILS_REQUEST,
    data
});

const createUserCFDetailsSuccess = (data) => ({
    type: Types.CREATE_USER_CF_DETAILS_SUCCESS,
    data
});

const createUserCFDetailsFailure = (error) => ({
    type: Types.CREATE_USER_CF_DETAILS_FAILURE,
    error
});

const createUserCFDetails = (payload) => (dispatch, getState) => {
    dispatch(createUserCFDetailsRequest());
    const {user: {secureToken, mobile} } = getState();
    return new Promise((resolve, reject) => {
        CFService.createUserCFDetails({...payload, userDetail: {phoneNumber: mobile}}, secureToken)
            .then(response => {
                dispatch(createUserCFDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(createUserCFDetailsFailure(error));
                reject(error);
            });
    });
};

const updateUserCFDetailsRequest = () => ({
    type: Types.UPDATE_USER_CF_DETAILS_REQUEST
});

const updateUserCFDetailsSuccess = (data) => ({
    type: Types.UPDATE_USER_CF_DETAILS_SUCCESS,
    data
});

const updateUserCFDetailsFailure = (error) => ({
    type: Types.UPDATE_USER_CF_DETAILS_FAILURE,
    error
});

const updateUserCFDetails = (payload) => (dispatch, getState) => {
    dispatch(updateUserCFDetailsRequest());
    const {user: {secureToken}} = getState();
    return new Promise((resolve, reject) => {
        CFService.updateUserCFDetails(payload, secureToken)
            .then(response => {
                dispatch(updateUserCFDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(updateUserCFDetailsFailure(error));
                reject(error);
            });
    });
};

const setUserCfBackURL = (backURL) => ({
    type: Types.SET_USER_CF_BACK_URL,
    backURL
});

const resetUserCfDetails = () => ({
    type: reducerConstants.USER_CF_DETAILS
});

export {
    setCarCfFlow,
    getUserCFDetails,
    createUserCFDetails,
    updateUserCFDetails,
    setUserCfBackURL,
    resetUserCfDetails
};
