import { createTypes } from "reduxsauce";

export default createTypes(
    `
    INITIALIZE_REDUCER
`,
    {
        prefix: "testingInjection/"
    }
);
