/* eslint-disable max-nested-callbacks */
/* eslint-disable no-shadow */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

// Constants
import { CHANNEL_TYPES } from "../../../constants/api-constants";
import { COOKIE_CONSTANTS, LOGIN_TITLE, NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { SOURCES } from "./constants";

// Styles
import styles from "./styles.css";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";

// Components
const CreateAccount = loadable(() => import("./create-account"), { ssr: false });
const MobileLogin = loadable(() => import("./mobile-login"), { ssr: false });
const PanelHeaderRevamp = loadable(() => import("../panel-header-revamp/component"), { ssr: false });
import Modal from "../../shared/modal";
import Button from "../../shared/button";

// Utilities
import nameValidator from "../../../utils/validators/name-validator";
import { validateEmail } from "../../../utils/validators/email-validator";
import { mobileNumberValidatorAE as mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";

import Checkbox from "../../shared/checkbox/index";
import gaId from "../../../utils/helpers/get-ga-from-cookie";
import { mobileLastDigit } from "../../../utils/helpers/mobile-last-digit";
// Icons
import IconWhatsapp from "./images/icon-whatsapp.svg";
import saveCookie from "../../../utils/helpers/save-cookie";
import { trackMobileSellerCustomEvents } from "../../../tracking/index";
import { SELLER_EVENTS } from "../../../tracking/ae.mobile-seller-events";
import cleverTap from "../../../tracking/clevertap";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import WeekendSaleStrip from "../weekend-sale-strip";

const STEPS = {
    LOGIN: "LOGIN",
    CREATE_ACCOUNT: "CREATE_ACCOUNT"
};

const GA_EVENTS_INITIAL_STATE = {
    phone: false,
    otp: false,
    name: false,
    email: false
};

const CUSTOM_DATA = {
    title: "Login/signup",
    helpIcon: false
};

const Login = ({
    name,
    email,
    mobile,
    onClose,
    children,
    isVisible,
    isLoggedIn,
    onSuccess,
    isDNDActive,
    isWAConsent,
    sendOTPConnect,
    verifyOtpConnect,
    fetchUserConsentConnect,
    updateProfileConnect,
    updateUserConsentConnect,
    updateWAStatusConnect,
    setProfileWithTokenConnect,
    loginSource,
    loginFor,
    userProfileConnect,
    customObj = CUSTOM_DATA,
    customOverlayClass = "",
    cityCode,
    lat,
    lng,
    loanEligibility,
    activeSaleConfig
}) => {
    const [activeStep, setActiveStep] = useState(STEPS.LOGIN);
    const [userMobile, setUserMobile] = useState("");
    const [disablePhone, setDisablePhone] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const initialWaStatus = useRef(null);
    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [accountDetails, setAccountDetails] = useState({ name, email });
    const [gaEvent, setGaEventSent] = useState(GA_EVENTS_INITIAL_STATE);
    const { lid = "", carMakeModel = "", city = "" } = useParams();
    const userMobileRef = useRef();
    const otpRef = useRef();
    const { title, helpIcon, isSeller, progress } = customObj || {};

    const history = useHistory();

    const focusPhone = () => {
        if (userMobileRef && userMobileRef.current) {
            window.setTimeout(() => {
                userMobileRef.current.focus();
            }, NUMBER.THREE_HUNDRED);
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            setAccountDetails({ name: "", email: "" });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!disablePhone && userMobile.length === NUMBER.TEN) {
            focusPhone();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disablePhone]);

    useEffect(() => {
        if (mobileNumberValidator(userMobile, true) && !isLoggedIn) {
            if (isSeller) {
                trackMobileSellerCustomEvents(SELLER_EVENTS.enterMobileNumber, { eventLabel: "Seller" });
                cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.SELL_SEND_OTP, { phone: `${userMobile}` });
            } else {
                trackMobileCustomEventsAE("enterMobileNumber", {
                    eventLabel: "NA"
                });
            }
            const trimmedNumber = userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile;
            fetchUserConsentConnect({ uid: trimmedNumber }).then((consentDetail = {}) => {
                initialWaStatus.current = consentDetail?.whatsapp;
            }).finally(() => {
                sendOTPConnect(trimmedNumber).then(() => {
                    setShowOtpField(true);
                    setShowTimer(true);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMobile]);

    const resetErrorMessage = () => {
        setErrorMessage("");
    };

    const onMobileChange = (e) => {
        const { value } = e.target;
        setUserMobile(value);
        if (value.length === 1 && !gaEvent.phone) {
            if (isSeller) {
                trackMobileSellerCustomEvents(SELLER_EVENTS.b2cMobileNumberDigit, { eventLabel: "Seller" });
                cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.B2C_MOBILE_NUMBER_1DIGIT, { label: "Seller" });
            } else trackMobileCustomEventsAE("b2cMobileNumber1digit");
            setGaEventSent({ phone: true, ...gaEvent });
        }
        if (errorMessage) resetErrorMessage();
    };

    const handleUserLogin = (inputOtp) => {
        if (isSeller) {
            trackMobileSellerCustomEvents(SELLER_EVENTS.verifyOtp, { eventLabel: "Seller" });
            cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.SELL_OTP_VERIFY_CLICK);
        } else trackMobileCustomEventsAE("otpVerify");
        resetErrorMessage();
        setShowLoader(true);
        const params = {
            username: userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile,
            otp: inputOtp
        };
        return new Promise((resolve, reject) => {
            verifyOtpConnect(params).then(response => {
                setProfileWithTokenConnect(response.access_token, { updateDnd: true, isWhatsappOptIn: !isDNDActive }).then((profileResponse) => {
                    const { mobilePhone, country, name } = profileResponse;
                    userProfileConnect(response.access_token, { uid: mobilePhone, country, phoneNumber: mobilePhone, userName: name, gaId, cityCode, lat, lng, userFinancialDetail: loanEligibility });
                    setShowLoader(false);
                    saveCookie(COOKIE_CONSTANTS.USER_MOBILE, mobilePhone);
                    // Uncomment for user based sale
                    saveCookie(COOKIE_CONSTANTS.TOKEN, response.access_token);
                    resolve({ ...profileResponse, token: response.access_token });
                });
                // Update WhatsApp Notification Preference
                if (initialWaStatus.current !== isWAConsent) {
                    const consentPayload = {
                        uid: userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile,
                        optIn: isWAConsent,
                        channel: CHANNEL_TYPES.WHATSAPP
                    };
                    updateUserConsentConnect(consentPayload);
                }
                if (loginFor === SOURCES.WISHLIST) {
                    trackMobileCustomEventsAE("loginWishlist", { eventLabel: "login/wishlist" });
                } else if (loginFor === SOURCES.MENU) {
                    trackMobileCustomEventsAE("loginMenu", { eventLabel: "login/menu" });
                } else if (loginFor === SOURCES.MY_BOOKINGS) {
                    trackMobileCustomEventsAE("loginMyBookings", { eventLabel: "login/My Bookings" });
                } else if (loginFor === SOURCES.SELL_ORDER) {
                    trackMobileCustomEventsAE("loginsellorder", { eventLabel: "login/sell order" });
                } else if (loginFor === SOURCES.ADD_TO_WISHLIST) {
                    trackMobileCustomEventsAE("loginAddToWishlist", { eventLabel: "login/add to wishlist" });
                } else if (loginFor === SOURCES.FINANCING) {
                    trackMobileCustomEventsAE("loginFinancing", { eventLabel: "login/Financing" });
                } else if (loginFor === SOURCES.START_PURCHASE) {
                    trackMobileCustomEventsAE("loginStartPurchase", { eventLabel: "login/Start Purchase" });
                } else if (loginFor === SOURCES.START_PURCHASE) {
                    trackMobileCustomEventsAE("loginStartPurchase", { eventLabel: "login/Start Purchase" });
                } else if (loginFor === SOURCES.C2C_MARKET_PLACE || loginFor === SOURCES.C2C_SELLER_CENTER) {
                    trackMobileCustomEventsAE("c2cLogin", { eventLabel: loginFor });
                } else if (loginFor === SOURCES.SALE_SNACKBAR) {
                    trackMobileCustomEventsAE("saleSnackbarLogin", { eventLabel: "sale_snackbar" });
                } else if (loginFor === SOURCES.SALE_BANNER_LISTING) {
                    trackMobileCustomEventsAE("saleListingBannerLogin", { eventLabel: "sale_banner_listing" });
                } else if (loginFor === SOURCES.SALE_BANNER_HOME) {
                    trackMobileCustomEventsAE("saleHomeBannerLogin", { eventLabel: "sale_banner_home" });
                } else if (loginFor === SOURCES.DP_SALE_LINE) {
                    trackMobileCustomEventsAE("dpSaleLineLogin", { eventLabel: "dp_sale_line" });
                } else if (isSeller) cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.SELL_OTP_VERIFIED, { phone: `${userMobile}`, isVerified: true });
                trackMobileCustomEventsAE("postBCLogin", { eventAction: mobileLastDigit(userMobile) });
            }).catch(error => {
                reject(error);
                setShowLoader(false);
                setErrorMessage("Please enter the correct verification code");
                cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.SELL_OTP_VERIFIED, { phone: `${userMobile}`, isVerified: false });
            });
        });
    };

    const validateOtp = (number) => {
        return number.length === NUMBER.FOUR;
    };

    const onOtpChange = (number) => {
        setOtp(number);
        if (number.length === NUMBER.FOUR && !gaEvent.otp) {
            if (isSeller) {
                trackMobileSellerCustomEvents(SELLER_EVENTS.otpEntered, { eventLabel: "Seller" });
                cleverTap.mobileSellerCleverTapEvent(cleverTap.mobileConstants.SELL_OTP_ENTERED);
            } else {
                trackMobileCustomEventsAE("b2cOtpEntered", {
                    eventLabel: "NA"
                });
            }
            setGaEventSent({ otp: true, ...gaEvent });
        }
    };

    const handleAccountDetails = (e) => {
        const { value, id: key } = e.target;
        setAccountDetails({ ...accountDetails, [key]: value });
        if (key === "name" && value.length === 1 && !gaEvent.name) {
            trackMobileCustomEventsAE("b2cNameEntered");
            setGaEventSent({ name: true, ...gaEvent });
        }
        if (key === "email" && value.length === 1 && !gaEvent.email) {
            trackMobileCustomEventsAE("b2cEmailEntered");
            setGaEventSent({ email: true, ...gaEvent });
        }
    };

    const handleProfile = async (token) => {
        await yieldToMainThread();
        setShowLoader(true);
        // eslint-disable-next-line no-shadow
        const { name, email } = accountDetails;
        updateProfileConnect(name, email, token).then(() => {
            setShowLoader(false);
            const cbProps = { name, email, token };
            onSuccess(cbProps);
        }).catch(() => {
            setShowLoader(false);
        });
    };

    const triggerGA4LoginEvent = async () => {
        await yieldToMainThread();
        trackMobileCustomEventsAE("ga4Login", {
            user_id: userMobile,
            //is_new_user: IS_NEW_USER.SIGN_IN,
            user_status: USER_LOGIN_STATUS.LOGGED_IN,
            login_type: USER_LOGIN_MEDIUM.PHONE
        });
    };

    const onSubmitUserDetails = async (loginOtp) => {
        await yieldToMainThread();
        setDisablePhone(true);
        handleUserLogin(loginOtp).then((response) => {
            const { name } = response;
            if ((lid && carMakeModel && city) || name || isSeller) {
                triggerGA4LoginEvent();
                onSuccess(response);
                if (loginFor === SOURCES.SAVE_FILTERS) {
                    trackMobileCustomEventsAE("loginMenu", { eventLabel: "save_search_snackbar" });
                } else if (loginFor === SOURCES.LISTING_SNACKBAR) {
                    trackMobileCustomEventsAE("loginMenu", { eventLabel: "listing_login_snackbar" });
                }
            } else {
                setActiveStep(STEPS.CREATE_ACCOUNT);
                if (loginFor === SOURCES.SELL_CAR_LISTING_BANNER) {
                    onSuccess(response);
                }
            }
        });
    };

    const handle = (value) => {
        switch (activeStep) {
            case STEPS.LOGIN:
                onSubmitUserDetails(value);
                break;
            case STEPS.CREATE_ACCOUNT:
                handleProfile();
                break;
            default:
                break;
        }
    };

    const onResendClick = () => {
        setShowTimer(true);
        resetErrorMessage();
        sendOTPConnect(userMobile);
    };

    const onCompleteTimer = () => {
        setShowTimer(false);
    };

    const onWhatsappToggle = () => {
        updateWAStatusConnect(!isWAConsent);
    };

    const showTooltip = () => setTooltipVisible(true);
    const hideTooltip = () => setTooltipVisible(false);

    const isMobileNumberValid = mobileNumberValidator(userMobile, true);
    const isBtnDisabled = validateOtp(otp) && isMobileNumberValid ? false : true;
    const disabledSubmitAction = activeStep === STEPS.LOGIN ? isBtnDisabled : false;

    const showSaleBanner = !isSeller && loginSource !== LOGIN_TITLE.BANK_VALUATION_CERTIFICATE;

    const mobileLoginProps = {
        userMobile,
        otp,
        onMobileChange,
        onOtpChange,
        hideTooltip,
        showTooltip,
        showOtpField,
        tooltipVisible,
        onCompleteTimer,
        onResendClick,
        onWhatsappToggle,
        handle,
        showTimer,
        errorMessage,
        userMobileRef,
        otpRef,
        isMobileNumberValid,
        isWhatsappChecked: !isDNDActive,
        loginSource,
        isSeller: !!isSeller,
        loginFor
    };

    const createAccountProps = {
        mobile,
        ...accountDetails,
        handleAccountDetails,
        nameValidator,
        validateEmail
    };

    const onLoginClose = async () => {
        await yieldToMainThread();
        if (onClose) {
            onClose();
        } else {
            history.goBack();
        }
    };

    return (
        <React.Fragment>
            {children}
            {isVisible && (
                <Modal isOpen={true} overlayClass={customOverlayClass}>
                    <div styleName={"styles.loginModal"}>
                        <PanelHeaderRevamp backClickHandler={onLoginClose} progress={progress} title={title} showHelpButton={helpIcon} />
                        {showSaleBanner && activeSaleConfig?.active && <WeekendSaleStrip />}
                        {activeStep === STEPS.LOGIN && <MobileLogin {...mobileLoginProps} />}
                        {activeStep === STEPS.CREATE_ACCOUNT && <CreateAccount {...createAccountProps} />}
                        <div styleName={"styles.buttonWrap"}>
                            <div styleName={"styles.selectPriceWhatsapp"}>
                                <Checkbox name={"whatsappConsent"} onChange={onWhatsappToggle} checkedStatus={isWAConsent} />
                                <p>Allow notifications on </p>
                                <p><img src={IconWhatsapp} alt="WhatsApp Icon" />Whatsapp</p>
                            </div>
                            <Button
                                text="VERIFY"
                                onClick={handle}
                                isLoading={showLoader}
                                disabled={disabledSubmitAction}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

Login.defaultProps = {
    onSuccess: () => { }
};

Login.propTypes = {
    isFetched: PropTypes.bool,
    mobile: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    sendOTPConnect: PropTypes.func,
    verifyOtpConnect: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onClose: PropTypes.func,
    userProfileConnect: PropTypes.func,
    isVisible: PropTypes.bool,
    isDNDActive: PropTypes.bool,
    isWAConsent: PropTypes.bool,
    setProfileWithTokenConnect: PropTypes.func,
    onSuccess: PropTypes.func,
    getTokenInfoConnect: PropTypes.func,
    updateUserConsentConnect: PropTypes.func,
    fetchUserConsentConnect: PropTypes.func,
    updateProfileConnect: PropTypes.func,
    updateWAStatusConnect: PropTypes.func,
    loginSource: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    showLogo: PropTypes.func,
    customObj: PropTypes.object,
    loginFor: PropTypes.string,
    customOverlayClass: PropTypes.string,
    cityCode: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    loanEligibility: PropTypes.object,
    activeSaleConfig: PropTypes.object
};

export default Login;
