/* eslint-disable max-params */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { NUMBER } from "../../constants/app-constants";
import { INSPECTION_TYPE } from "../../constants/seller-app-constants";
import { appUrl } from "../../constants/url-constants";
import gaId from "../../utils/helpers/get-ga-from-cookie";
import sellerReinspectionChecker from "../helpers/seller-reinspection-checker";

export const generateSellOnlineAppointmentPayload = (data, selectedCity, user) => {
    const { mobile, name, gaId: userGaId } = user;
    const {
        year,
        engineSize,
        transmission,
        fuelType,
        noOfCylinders,
        make,
        model,
        variant,
        customer,
        rqstCallback,
        bodyType,
        siQuestions
    } = data;
    return ({
        city: {
            code: selectedCity?.code,
            name: selectedCity?.name
        },
        year,
        engineSize: parseFloat(engineSize),
        transmission,
        fuelType,
        noOfCylinders,
        make,
        model,
        variant: (variant && typeof variant === "string") ? variant.split("|")[0]?.trim() : null,
        variantAbsent: true,
        phone: mobile,
        whatsappConsent: customer?.whatsappConsent,
        name: name || customer?.name,
        dsOfferedPrice: 0,
        requestCallback: rqstCallback,
        bodyType,
        gaId: userGaId || gaId,
        questions: siQuestions
    });
};

export const generateConfirmAppointmentPayload = (data, inspectionTypeData, isWeb = false, isWebview = false) => {
    const { zoneId = 1, customer, appointmentId, bookingDetail, dateTimeSlot, reInspectionStatus, inspectionModeTypes, inspectionType: prevInspectionType } = data;
    const { address, date, startTime, endTime, timeSlotBucket, storeId, inspectionType, slotKey, latestInspectionType } = bookingDetail;
    const from = +startTime % NUMBER.TWELVE;
    const to = +endTime % NUMBER.TWELVE;
    const walkInAppointment = prevInspectionType === INSPECTION_TYPE.WALK_IN;
    const isReinspectionAppointment = sellerReinspectionChecker(latestInspectionType, reInspectionStatus);
    const homeInspectionDetials = inspectionModeTypes?.find(v => v.inspectionType === prevInspectionType);
    const {amount, active} = homeInspectionDetials || {};
    let allSlots = {};
    if (inspectionTypeData === INSPECTION_TYPE.HOME || isReinspectionAppointment) {
        allSlots = [...dateTimeSlot[date].popularSlots, ...dateTimeSlot[date].otherSlots];
    } else {
        allSlots = dateTimeSlot[date].timeSlots;
    }

    const paymentPayload = {
        amount,
        returnUrl: !isWeb ? `${appUrl()}/book-appointment/slots?lid=${appointmentId}&paymentDone=pending` : `${appUrl()}/sell-used-car/?stepId=slots&lid=${appointmentId}&paymentDone=pending`
    };
    const isPaidInspection = amount > 0 && active;
    const selectedSlot = allSlots.find(v => (v.displaySlots?.toLowerCase() === timeSlotBucket?.toLowerCase() || (+v.startTime === from && +v.endTime === to))) || {};
    const homeInspectionPayload = {
        zoneId,
        storeId: walkInAppointment ? storeId : null,
        slotDate: date,
        slot: walkInAppointment ? { slotKey: slotKey || timeSlotBucket } : { ...selectedSlot },
        source: isWebview ? "SELLER_APP" : "MSITE",
        paymentRequest: isPaidInspection ? paymentPayload : null,
        user: customer?.phone,
        address: inspectionType === INSPECTION_TYPE.ONLINE ? {} : {
            line1: address?.address1 || "",
            line2: address?.address2 || "",
            line3: address?.location || "",
            latitude: address?.lat,
            longitude: address?.lng
        }
    };
    return homeInspectionPayload;
};

export const getLeadHeader = (data) => {
    try {
        const { engine, variant, year, make: { name: makeName }, model: { name: modelName } } = data;
        return `${year} ${makeName || ""} ${modelName || ""} ${variant || ""} ${engine || ""}`;
    } catch (error) {
        return "";
    }

};
