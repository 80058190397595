// Constants
import { TRANSACTION_TYPE } from "../../../constants/ae/post-booking-constants";
import { BOOKING_TYPE, NUMBER } from "../../../constants/app-constants";
import { reducerConstants } from "../../../constants/reducer-constant";

// Service
import { DeliveryService, couponService, CheckoutAEService as CheckoutService } from "../../../service";

// Types
import Types from "./types";

const resetPostBcDetails = () => ({
    type: reducerConstants.POST_BC_DETAILS
});

const setIsMRL = () => (dispatch, getState) => {
    const { postBcDetails: { order } } = getState();
    const { bookingType, bookingAmount } = order || {};
    dispatch({
        type: Types.SET_IS_MRL,
        isMRL: bookingType === BOOKING_TYPE.MRL && bookingAmount === NUMBER.ZERO
    });
};

const setScheduleTestDriveShowMoreCTA = (isSelected) => {
    return {
        type: Types.SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA,
        isSelected
    };
};

const fetchPostBookingConfigSuccess = (data) => ({
    type: Types.FETCH_POST_BC_CONFIG_SUCCESS,
    data
});

const fetchPostBookingConfigFailure = (error) => ({
    type: Types.FETCH_POST_BC_CONFIG_FAILURE,
    error
});

const fetchPostBookingConfig = (params) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((_, reject) => {
        DeliveryService.getVasConfig(secureToken, params)
            .then((response) => {
                dispatch(fetchPostBookingConfigSuccess(response?.data));
            }).catch((err) => {
                dispatch(fetchPostBookingConfigFailure(err));
                reject(err);
            });
    });
};

const fetchOrderPostBcDetailsInit = () => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_INIT
});

const fetchOrderPostBcDetailsSuccess = (data) => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_SUCCESS,
    data
});

const fetchOrderPostBcDetailsFailure = (error) => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_FAILURE,
    error
});

// Coupon info
const fetchCouponList = (params, version) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.getCouponList(params, version)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

const updateUnlockCoupon = (params, token) => () => {
    return new Promise((resolve, reject) => {
        couponService.unlockCoupon(params, token)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

// eslint-disable-next-line max-params
const fetchOrderPostBcDetailsV2 = (appId, token, poll = false, config = { withLoader: true }) => (dispatch) => {
    if (config.withLoader) dispatch(fetchOrderPostBcDetailsInit());
    return new Promise((resolve, reject) => {
        DeliveryService.fetchPostBcStepsDataV2(appId, token, poll).then((response) => {
            const paymentObj = response?.data?.steps.find(itr => itr.stepName === TRANSACTION_TYPE.TOKEN_PAYMENT) || {};
            const orderDetails = { ...response.data, paymentMode: paymentObj.paymentMode };
            const { bookingType, bookingAmount } = response?.data?.order || {};

            // Check and set if MRL
            dispatch({
                type: Types.SET_IS_MRL,
                isMRL: bookingType === BOOKING_TYPE.MRL && bookingAmount === NUMBER.ZERO
            });

            dispatch(fetchOrderPostBcDetailsSuccess(orderDetails));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchOrderPostBcDetailsFailure(error));
            reject(error);
        });
    });
};

const fetchActiveStepDataInit = () => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_INIT
});

const fetchActiveStepDataSuccess = (data) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_SUCCESS,
    data
});

const fetchActiveStepDataFailure = (error) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_FAILURE,
    error
});

const fetchActiveStepData = ({ orderId, stepType, withLoader = true }) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    if (withLoader) dispatch(fetchActiveStepDataInit());
    return new Promise((resolve, reject) => {
        DeliveryService.getPostBCActiveStepData({ orderId, stepType, token: secureToken })
            .then((response) => {
                dispatch(fetchActiveStepDataSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchActiveStepDataFailure());
                reject(error);
            });
    });
};

const resetActiveStepData = () => (dispatch) => {
    dispatch({ type: Types.RESET_ACTIVE_STEP_DATA });
};

const fetchTdAllActiveBookingsInit = () => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT
});

const fetchTdAllActiveBookingsSuccess = (data) => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS,
    data
});

const fetchTdAllActiveBookingsFailure = () => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE
});

const fetchTdAllActiveBooking = (mobile, secureToken) => (dispatch) => {
    dispatch(fetchTdAllActiveBookingsInit());
    return new Promise((resolve, reject) => {
        DeliveryService.fetchTdAllActiveBooking(mobile, secureToken)
            .then((response) => {
                const data = response?.data || [];
                dispatch(fetchTdAllActiveBookingsSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchTdAllActiveBookingsFailure());
                reject(err);
            });
    });
};

export {
    resetPostBcDetails,
    setIsMRL,
    setScheduleTestDriveShowMoreCTA,
    fetchPostBookingConfig,
    fetchOrderPostBcDetailsV2,
    fetchCouponList,
    updateUnlockCoupon,
    fetchActiveStepData,
    resetActiveStepData,
    fetchTdAllActiveBooking
};
