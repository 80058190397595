/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

// Style
import styles from "./styles.css";

// Utilities
import { makeOdometerLabelAE } from "../../../utils/helpers/make-odometer-label";

// Icons
import infoOutline from "./images/info.svg";
import LazyImage from "../lazy-image";

// Hooks
import useToggle from "../../../hooks/use-toggle";

// Component
import VasInfoModal from "../../ae.mobile/vas-info-modal";

// Constants
import { NUMBER } from "../../../constants/app-constants";
import ICONS_CAR_OVERVIEW from "./constants";
import { trackMobileCustomEventsAE } from "../../../tracking";

const BASIC_FILTER_KEYS = ["vin", "odometerReading", "specs"];

const InfoPopup = ({renderIcon, isDesktop, popupText, title}) => {
    return (
        <div styleName={"styles.infoWrapper"}>
            <div styleName={"styles.infoImg"}>
                {renderIcon}
            {isDesktop && (
                <span styleName={"styles.infoPopup"}>
                    <p>{title}</p>
                    {popupText}
                </span>
            )}
            </div>
        </div>
    );
};

const BasicDetail = ({ detail, isDesktop, assortmentCategory, accidentFreeInfo }) => {
    const [showOptionTypeDisclaimer, toggleShowOptionTypeDisclaimer] = useToggle();

    const handleOptionTypeClick =  () => {
        trackMobileCustomEventsAE("optionTypeInfo", {eventLabel: assortmentCategory});
        toggleShowOptionTypeDisclaimer(true);
    };

    let popupText = "";
    let popupTitle = "";

    if (detail.key === "accidentFree") {
        popupText = accidentFreeInfo;
        popupTitle = detail?.value || "";
    } else if (detail.key === "optionsType") {
        popupText = "Please note that option labels follow CARS24 terminology. They indicate how feature packed the car is and may or may not correspond with the option terminologies used by the manufacturer.";
    }

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.options"}>
                <span styleName={"styles.iconWrap"}>
                    <LazyImage
                        height={isDesktop ? NUMBER.TWENTY_FOUR : NUMBER.TWELVE}
                        width={isDesktop ? NUMBER.TWENTY_FOUR : NUMBER.TWELVE}
                        src={ICONS_CAR_OVERVIEW[detail?.key] || ICONS_CAR_OVERVIEW.defaultIcon}
                        alt={detail?.name}
                    />
                </span>
                <div styleName={"styles.valueWrapper"}>
                    <p styleName={isDesktop ? "styles.desktopPara" : ""}>{detail.key === "odometerReading" ? makeOdometerLabelAE(detail?.value || 0) : detail?.value}</p>
                    <div>
                        <InfoPopup
                            title={detail.key === "odometerReading" ? makeOdometerLabelAE(detail?.value || 0) : detail?.value}
                            isDesktop={isDesktop}
                            renderIcon={detail.key === "optionsType" || detail.key === "accidentFree" ? <LazyImage src={infoOutline} alt="info" height={16} width={16} onClick={isDesktop ? () => {} : handleOptionTypeClick} /> : null}
                            popupText={popupText}
                        />
                    </div>
                </div>
            </div>
            {showOptionTypeDisclaimer &&  popupText && (
                <VasInfoModal
                    heading={popupTitle}
                    text={popupText}
                    onClose={toggleShowOptionTypeDisclaimer}
                />
            )}
        </div>
    );
};

const BasicDetailsContent = ({
    basicDetails,
    isDesktop = false,
    assortmentCategory,
    accidentFreeInfo
}) => {
    const getFilteredDetails = () => {
        // Check if fuelEfficiency is present
        const hasFuelEfficiency = basicDetails?.some(detail => detail.key === "fuelEfficiency");

        // Filter the basicDetails array
        const filteredDetails = (basicDetails || [])?.filter(detail => {
            // Remove elements with keys in BASIC_FILTER_KEYS
            if (BASIC_FILTER_KEYS.includes(detail.key)) {
                return false;
            }
            // Remove fuelType if fuelEfficiency is present
            if (hasFuelEfficiency && detail.key === "fuelType") {
                return false;
            }
            return true;
        });
        return filteredDetails || [];
    };
    return (
        <div styleName={isDesktop ? "styles.mainSectionWrapDesktop" : "styles.mainSectionWrap"}>
            <div styleName={`styles.outer ${isDesktop ? "styles.outerDesktop" : ""}`}>
                {(getFilteredDetails().map((item, index) => <BasicDetail isDesktop={isDesktop} assortmentCategory={assortmentCategory} detail={item} key={`${item.key}_${index}`} accidentFreeInfo={accidentFreeInfo} />))}
            </div>
        </div>
    );
};

BasicDetailsContent.propTypes = {
    basicDetails: PropTypes.array,
    isDesktop: PropTypes.bool,
    appointmentId: PropTypes.string,
    assortmentCategory: PropTypes.string,
    accidentFreeInfo: PropTypes.string
};

BasicDetail.propTypes = {
    isDesktop: PropTypes.bool,
    detail: PropTypes.object,
    assortmentCategory: PropTypes.string,
    accidentFreeInfo: PropTypes.string
};

InfoPopup.propTypes = {
    isDesktop: PropTypes.bool,
    title: PropTypes.string,
    renderIcon: PropTypes.object,
    popupText: PropTypes.string
};

export default BasicDetailsContent;
