/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withAuth from "../../shared/with-auth";
import { showWishlistPopup, fetchWishlistedCarsList, addOrRemoveFromWishlist } from "../my-wishlist/actions";
import { showToast } from "../../shared/toast-message/actions";
import { TOAST_MESSAGE, TOAST_VARIANT } from "../../shared/toast-message/constant";
import cleverTap from "../../../tracking/clevertap";

export default function withWishlist(Component) {
    const WithWishlist = (props) => {
        const {
            wishlistedCarsList,
            addOrRemoveFromWishlistConnect,
            secureToken,
            showToastConnect,
            booked: isReserved = false,
            carInfo
        } = props;

        const { booked: isReservedDetails = false} = carInfo || {};
        const isWishlistedCar = (carId) => {
            return (wishlistedCarsList || []).indexOf(carId) !== -1;
        };

        const onClickAddToWishList = (vehicleId, token, {
            removeFromWishlist = false,
            callback = () => {},
            errorCallback = () => {},
            toastStyle
        } = {}, isNotified = true) => {
            let shortListed = !isWishlistedCar(vehicleId);
            if (removeFromWishlist) {
                shortListed = false;
            }
            addOrRemoveFromWishlistConnect(token ? token : secureToken, {
                shortListed,
                vehicleId,
                isNotified: isReserved || isReservedDetails ?  false : isNotified
            }).then(() => {
                if (shortListed) {
                    showToastConnect({
                        variant: TOAST_VARIANT.HEART,
                        text: TOAST_MESSAGE.addedToWishlist,
                        ...(toastStyle && { toastStyle })
                    });
                    cleverTap.updateWishlistedCarsBool(true);
                } else {
                    showToastConnect({
                        variant: TOAST_VARIANT.FAILURE,
                        text: TOAST_MESSAGE.removedFromWishlist,
                        ...(toastStyle && { toastStyle })
                    });
                }
                callback(shortListed);
            }).catch(errorCallback);
        };

        return (
            <Component
                {...props}
                isWishlistedCar={isWishlistedCar}
                onClickAddToWishList={onClickAddToWishList}
            />
        );
    };

    WithWishlist.propTypes = {
        showWishlistPopupConnect: PropTypes.func,
        fetchWishlistedCarsListConnect: PropTypes.func,
        wishlistedCarsList: PropTypes.array,
        addOrRemoveFromWishlistConnect: PropTypes.func,
        secureToken: PropTypes.string,
        showToastConnect: PropTypes.func,
        carInfo: PropTypes.object,
        booked: PropTypes.bool
    };

    const mapStateToProps = ({ myWishlist: { wishlistedCarsList } }) => {
        return { wishlistedCarsList };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        showWishlistPopupConnect: showWishlistPopup,
        fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
        addOrRemoveFromWishlistConnect: addOrRemoveFromWishlist,
        showToastConnect: showToast
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        withAuth(WithWishlist)
    );
}
