import { NUMBER } from "../../constants/app-constants";
import { FILTER_ENTITIES, FILTER_TYPES } from "./constants";

const getAppliedOptions = (filterKey, options, params) => {
    const { filterType, optionKey } = params || {};
    const appliedFilters = [];

    options.forEach((option) => {
        if (option.subFilter) {
            option.subFilter.options.forEach((subFilterOption, index) => {
                appliedFilters.push({
                    filterKey,
                    optionKey: option.key,
                    subOptionKey: subFilterOption.key,
                    displayText: `${option.displayText} ${subFilterOption.displayText}`,
                    isSubFacetFullySelected: option?.isSubFacetFullySelected || false,
                    ...(index === NUMBER.ZERO && { key: "make" })
                });
            });

        } else if (filterKey === FILTER_ENTITIES.COMING_SOON) {
            appliedFilters.push({
                filterKey,
                optionKey: filterKey,
                displayText: option.displayText
            });
        } else if (filterKey === FILTER_ENTITIES.DISCOUNT || filterKey === FILTER_ENTITIES.LIGHTENING_DEAL || filterKey === FILTER_ENTITIES.LOW_IMPERFECTION) {
            appliedFilters.push({
                filterKey: filterKey === FILTER_ENTITIES.LOW_IMPERFECTION ? FILTER_ENTITIES.LOW_IMPERFECTION :  FILTER_ENTITIES.DISCOUNT,
                optionKey: filterKey,
                displayText: option.displayText
            });
        } else if (filterType === FILTER_TYPES.GF) {
            appliedFilters.push({
                filterKey,
                optionKey,
                filterType,
                subOptionKey: option.displayText,
                displayText: option.displayText
            });
        } else {
            appliedFilters.push({
                filterKey,
                optionKey: option.key,
                displayText: option.displayText
            });
        }
    });
    return appliedFilters;
};

const initAppliedFilters = (filters) => {
    let appliedFilters = [];
    let appliedSuggestions = [];
    Object.keys(filters).forEach((filterKey) => {
        const {
            filterType,
            min,
            max,
            options,
            suggestions
        } = filters[filterKey];

        if (filterType === FILTER_TYPES.RF) {
            appliedFilters.push({
                filterKey,
                isRangeFilter: true,
                min,
                max
            });
        }

        if (filterType === FILTER_TYPES.GF) {
            const { groupFacet } = filters[filterKey];
            Object.values(groupFacet).forEach((option) => {
                if (option.filterType === FILTER_TYPES.RF) {
                    appliedFilters.push({
                        filterKey,
                        optionKey: option.key,
                        displayText: option.displayText,
                        filterType,
                        isRangeFilter: true,
                        ...(
                            option.filterType === FILTER_TYPES.RF && {

                                min: option.min,
                                max: option.max
                            }
                        )
                    });
                }
                if (option.options) {
                    appliedFilters = [
                        ...appliedFilters,
                        ...getAppliedOptions(filterKey, option.options, { optionKey: option.key, filterType: FILTER_TYPES.GF })
                    ];
                }
            });
        }

        if (options) {
            appliedFilters = [
                ...appliedFilters,
                ...getAppliedOptions(filterKey, options)
            ];
        }

        if (suggestions) {
            appliedSuggestions = [
                ...appliedFilters,
                ...getAppliedOptions(filterKey, suggestions)
            ];
        }

    });

    return {
        selectedFilters: appliedFilters,
        selectedSuggestions: appliedSuggestions,
        appliedFilters,
        appliedSuggestions
    };
};

export default initAppliedFilters;
