import { CF_STATUS } from "../../../constants/ae/cf-constants";
import { reducerConstants } from "../../../constants/reducer-constant";
import { CFService } from "../../../service";
import Types from "./types";

const getCarCfDetailsRequest = (data) => ({
    type: Types.GET_CAR_CF_DETAILS_REQUEST,
    data
});

const getCarCfDetailsSuccess = (data) => ({
    type: Types.GET_CAR_CF_DETAILS_SUCCESS,
    data
});

const getCarCfDetailsFailure = (error) => ({
    type: Types.GET_CAR_CF_DETAILS_FAILURE,
    error
});

const getCarCfDetails = (appointmentId) => (dispatch, getState) => {
    dispatch(getCarCfDetailsRequest());
    const {user: {secureToken}} = getState();
    if (!secureToken) {
        return false;
    }
    return new Promise((resolve, reject) => {
        CFService.getCarCfDetails(appointmentId, secureToken)
            .then(response => {
                dispatch(getCarCfDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getCarCfDetailsFailure(error));
                // eslint-disable-next-line no-use-before-define
                // dispatch(createCarCfDetails(appointmentId));
                reject(error);
            });
    });
};

const createCarCfDetailsRequest = (data) => ({
    type: Types.CREATE_CAR_CF_DETAILS_REQUEST,
    data
});

const createCarCfDetailsSuccess = (data) => ({
    type: Types.CREATE_CAR_CF_DETAILS_SUCCESS,
    data
});

const createCarCfDetailsFailure = (error) => ({
    type: Types.CREATE_CAR_CF_DETAILS_FAILURE,
    error
});

const createCarCfDetails = (appointmentId, payload = {status: CF_STATUS.RECREATE_OFFER}) => (dispatch, getState) => {
    dispatch(createCarCfDetailsRequest());
    const {user: {secureToken},
        checkout: { order: {orderId}}
    } = getState();
    return new Promise((resolve, reject) => {
        CFService.createCarCfDetails({...payload, appointmentId, orderId }, secureToken)
            .then(response => {
                dispatch(createCarCfDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(createCarCfDetailsFailure(error));
                reject(error);
            });
    });
};

const updateCarCfDetailsRequest = () => ({
    type: Types.UPDATE_CAR_CF_DETAILS_REQUEST
});

const updateCarCfDetailsSuccess = (data) => ({
    type: Types.UPDATE_CAR_CF_DETAILS_SUCCESS,
    data
});

const updateCarCfDetailsFailure = (error) => ({
    type: Types.UPDATE_CAR_CF_DETAILS_FAILURE,
    error
});

const updateCarCfDetails = (payload) => (dispatch, getState) => {
    dispatch(updateCarCfDetailsRequest());
    const {user: {secureToken},
        checkout: { order: {orderId}}
    } = getState();
    return new Promise((resolve, reject) => {
        CFService.updateCarCfDetails({...payload, orderId}, secureToken)
            .then(response => {
                dispatch(updateCarCfDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(updateCarCfDetailsFailure(error));
                reject(error);
            });
    });
};

const setCarCfBackURL = (backURL) => ({
    type: Types.SET_CAR_CF_BACK_URL,
    backURL
});

const resetCarCfDetails = () => dispatch => {
    dispatch({ type: reducerConstants.CAR_CF_DETAILS });
};

export {
    getCarCfDetails,
    createCarCfDetails,
    updateCarCfDetails,
    setCarCfBackURL,
    resetCarCfDetails
};
