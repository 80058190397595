import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import PostBcTdWhatsNext from "./component";

const mapStateToProps = ({
    carDetails: {
        content: {
            isComingSoon = false
        } = {}
    } = {}
}) => ({
    isComingSoon
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBcTdWhatsNext);
