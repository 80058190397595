import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_EVALUATION_BRAND
    SET_EVALUATION_MODEL
    SET_EVALUATION_YEAR
    SET_EVALUATION_CUSTOMER
    SET_EVALUATION_DATA
    SET_EVALUATION_DATA_BY_VARIANTS
    SET_BOOKING_DETAILS

    CREATE_LEAD_APPOINTMENT_REQUEST
    CREATE_LEAD_APPOINTMENT_SUCCESS
    CREATE_LEAD_APPOINTMENT_FAILURE

    FETCH_LEAD_APPOINTMENT_INFO_REQUEST
    FETCH_LEAD_APPOINTMENT_INFO_SUCCESS
    FETCH_LEAD_APPOINTMENT_INFO_FAILURE

    CAR_SELF_INSPECTION_REQUEST
    CAR_SELF_INSPECTION_SUCCESS
    CAR_SELF_INSPECTION_FAILURE
    RESET_BVC_CAR_EVALUATION

    SET_SELLER_RA
    SET_CAR_CONDITION_STEP_INDEX
`,
    {
        prefix: "bvccarevaluationinfo/"
    }
);
