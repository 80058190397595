import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";

class ReducerManager {
    constructor() {
        this.store = null;
        this.reducers = {};
        this.persistor = null;
        this.persistConfig = {
            key: "root", // Default key
            storage,
            version: 1
        };
    }

    getFilteredState(initialState) {
        return Object.keys(this.reducers).reduce((prev, element) => {
            if (initialState[element]) {
                return {
                    ...prev,
                    [element]: initialState[element]
                };
            }
            return prev;
        }, {});
    }

    getReducersToInject(asyncReducers) {
        return Object.keys(asyncReducers).reduce((prev, element) => {
            if (!this.reducers[element]) {
                return {
                    ...prev,
                    [element]: asyncReducers[element]
                };
            }
            return prev;
        }, {});
    }

    injectReducer(asyncReducers = {}) {
        const reducerToInject = this.getReducersToInject(asyncReducers);
        if (typeof window === "object" && reducerToInject && Object.keys(reducerToInject).length) {
            Object.assign(this.reducers, reducerToInject);
            const persistedReducer = persistReducer(this.persistConfig, combineReducers(this.reducers));
            this.store.replaceReducer(persistedReducer);
            this.persistor.persist();
        }
    }

    configureStore({ baseReducer, initialState, enhancer, persistVersion, persistKey, whitelistedKeys, migration }) {
        this.reducers = {
            ...this.reducers,
            ...baseReducer
        };

        this.persistConfig = {
            ...this.persistConfig,
            version: persistVersion || this.persistConfig.version,
            key: persistKey || this.persistConfig.key,
            whitelist: whitelistedKeys || [],
            migrate: migration ? createMigrate(migration) : undefined
        };

        const persistedReducer = persistReducer(this.persistConfig, combineReducers(this.reducers));
        this.store = createStore(persistedReducer, this.getFilteredState(initialState), enhancer);
        this.persistor = persistStore(this.store, { manualPersist: true });

        // Automatically start persistence
        this.persistor.persist();

        return {
            store: this.store,
            persistor: this.persistor
        };
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ReducerManager();
        }
        return this.instance;
    }
}

export const reducerManager = ReducerManager.getInstance();
