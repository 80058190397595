import Types from "./types";

const initializeTestInjectionReducer = (source) => ({
    type: Types.INITIALIZE_REDUCER,
    source
});

export {
    initializeTestInjectionReducer
};
